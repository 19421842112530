// /auth/google/callback
import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../apis/axiosInstance';
import toast, { Toaster } from 'react-hot-toast';
import { usePlatformStore } from '@/hooks/usePlatformStore';
import { getConnectErrorPageUrl } from '@/biz/tool';
import { notMatch } from '@/biz/tool';
import LottieClerkLoading from '@/components/web/components/LottieClerkLoading';
import { Tracker } from '@/utils';
import { useUser } from '@clerk/clerk-react';

const ClerkCallbackPage: React.FC = () => {
  const { user: clerkUser } = useUser();

  const { influencers } = usePlatformStore();
  const hasJoinedRef = useRef(false);

  const search = window.location.search;

  const isSignup = new URLSearchParams(search)?.get('isSignup') === 'true';
  const redirect = new URLSearchParams(search)?.get('redirect') || '/';
  const ahaRedirect = new URLSearchParams(search)?.get('ahaRedirect') || '/';
  const urlShowModalAnalysis =
    new URLSearchParams(search)?.get('urlShowModalAnalysis') === 'true';
  const urlShowModalConfirmDealPrice =
    new URLSearchParams(search)?.get('urlShowModalConfirmDealPrice') === 'true';
  //
  const orderId = new URLSearchParams(search)?.get('orderId');
  const campaignId = new URLSearchParams(search)?.get('campaignId');
  const platform = new URLSearchParams(search)?.get('platform');
  const duringFirstOrder = new URLSearchParams(search)?.get('duringFirstOrder');
  //
  const contentType = new URLSearchParams(search)?.get('contentType');
  const contentGenre = new URLSearchParams(search)?.get('contentGenre');
  const price = new URLSearchParams(search)?.get('price');
  //
  const influencerId = new URLSearchParams(search)?.get('influencerId');
  const influencerUserEmail = new URLSearchParams(search)?.get(
    'influencerUserEmail'
  );
  const skipScript = new URLSearchParams(search)?.get('skipScript');
  //
  const REFERRALCODE = new URLSearchParams(search)?.get('REFERRALCODE');

  useEffect(() => {
    // // 废弃 // 登录携带join   [手动发join接口] ✅
    // 登录携带 urlShowModalAnalysis ✅
    // 登录判断 onboarding [用info接口判断] ✅
    // 登录判断与订单匹配 [用info接口判断] ✅
    // 登录携带referralCode   [手动发/user/setReferralCode接口] ✅

    // if (contentType && contentGenre && price) {
    //   joinCampaign();
    // } else {
    otherHandle();
    // }
  }, [influencers]);

  useEffect(() => {
    if (isSignup) {
      try {
        window?.ahaTracker &&
          window.ahaTracker.track([{ name: 'signUp', userId: clerkUser?.id! }]);
      } catch (error) {
        console.error(error);
      }
    }

    // 获取utm_medium和utm_source
    const utmMedium = window.localStorage.getItem('utm_medium');
    const utmSource = window.localStorage.getItem('utm_source');
    // console.log('utmMedium', utmMedium);
    // console.log('utmSource', utmSource);
    Tracker.gtag('event', 'click', {
      event_category: 'platform_influencer_sign_success',
      utm_source: utmSource || 'none',
      utm_medium: utmMedium || 'none',
      value: 1
    });
  }, []);

  const otherHandle = async () => {
    if (REFERRALCODE) {
      await setReferralCode();
    }
    redirectToAha();
  };

  const goToNotMatch = () => {
    let payload = {
      emails: [influencerUserEmail],
      orderId
    };
    window.location.href = getConnectErrorPageUrl({
      platform,
      type: 'notMatchOrderAndEmail',
      payload
    });
  };

  const joinCampaign = async () => {
    if (hasJoinedRef.current) {
      return;
    }

    try {
      hasJoinedRef.current = true;
      const params: any = {
        campaignId,
        platform,
        orderId
      };

      if (contentType && contentGenre && price) {
        params.interestedPriceFields = [
          {
            contentType,
            contentGenre,
            price: Number(price)
          }
        ];
      }
      if (skipScript) {
        params.skipScript = true;
      }
      const {
        data: { code, message }
      } = await axiosInstance.post(`/campaign/join`, params);

      toast.success('You have successfully applied!', { duration: 2000 });
      window.location.replace(ahaRedirect + '?joinCampaign=true');
    } catch (error: any) {
      if (error.code === 40010) {
        // The campaign quota is full: Join quota is not enough
        setTimeout(() => {
          toast.error(
            'We sincerely apologize, but this campaign has reached its capacity and no longer has available spots. Thank you for your understanding!'
          );
        }, 0);
        setTimeout(() => {
          window.location.replace(ahaRedirect + '?noQuota=true');
        }, 2000);
      }
      if (
        // 'Please log in to the correct account and join this campaign.'
        error.message.includes('Please log in to the correct account')
      ) {
        // 跳转到错误页面
        goToNotMatch();
      } else {
        setTimeout(() => {
          toast.error(error.message);
        }, 0);
        setTimeout(() => {
          window.location.replace(ahaRedirect);
        }, 2000);
      }

      console.error(`Failed to fetch : (/campaign/join) \n`, error);
    } finally {
      // return window.location.replace(ahaRedirect);
    }
  };

  const setReferralCode = async () => {
    try {
      const {
        data: { code, message }
      } = await axiosInstance.post(`/user/referralCode`, {
        referralCode: REFERRALCODE
      });
    } catch (error) {
      console.error('setReferralCode error: ', error);
    }
  };

  const redirectToAha = () => {
    console.log('influencers', influencers);

    setTimeout(() => {
      //
      if (influencers && influencers.length) {
        // 登录判断与订单匹配 [用info接口判断]
        // console.log('influencers', influencers);
        // console.log('influencerId', influencerId);
        // console.log('influencerUserEmail', influencerUserEmail);
        const isNotMatch = notMatch({ influencers, influencerId });
        // 不匹配
        if (isNotMatch) {
          if (influencerUserEmail && orderId) {
            goToNotMatch();
          } else {
            window.location.replace(ahaRedirect);
          }
        } else {
          if (urlShowModalAnalysis) {
            window.location.replace(
              ahaRedirect + '?urlShowModalAnalysis=true&&orderId=' + orderId
            );
          } else if (urlShowModalConfirmDealPrice) {
            window.location.replace(
              ahaRedirect +
                '?urlShowModalConfirmDealPrice=true&&orderId=' +
                orderId
            );
          } else {
            window.location.replace(ahaRedirect);
          }
        }
      }
      // 登录判断 onboarding [用info接口判断]
      if (influencers && !influencers.length && !urlShowModalAnalysis) {
        if (orderId) {
          if (influencerUserEmail) {
            // 新账号
            // 订单已经绑定账号
            goToNotMatch();
          } else {
            // 新账号
            // 订单未绑定账号
            window.location.replace(ahaRedirect);
          }
        } else {
          // 新账号
          // 首页登录
          window.location.replace('/connect');
        }
      }
    }, 0);
  };
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: 12,
            borderRadius: 4,
            background: '#1C2024',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            color: '#fff'
          }
        }}
      />
      <div
        style={{ display: 'none' }}
        className="flex flex-col justify-center items-center text-gray-500"
      >
        <div className="">{redirect}</div>
        ---
        <div className="">{contentType}</div>
        ---
        <div className="">{contentGenre}</div>
        ---
        <div className="">{price}</div>
        ---
        <div className="">{orderId}</div>
        ---
        <div className="">{campaignId}</div>
        ---
        <div className="">{platform}</div>
        ---
        <div className="">{duringFirstOrder}</div>
        ---
        <div className="">{influencerId}</div>
        ---
        <div className="">{influencerUserEmail}</div>
      </div>
      <div className="flex flex-col justify-center items-center h-screen text-gray-500">
        <div className="text-2xl mt-[-16%] flex flex-col justify-center items-center">
          <LottieClerkLoading />
          <p className="mt-4">Redirecting to Aha Creator...</p>
        </div>
      </div>
    </>
  );
};

export default ClerkCallbackPage;
