import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import axiosInstance from '@/apis/axiosInstance';
import {
  EmptyBrandIcon,
  AssetsAnnexIcon,
  CardAnnexIcon,
  BrandAnnexIcon,
  DownloadIcon,
  WarnOutlineIcon
} from '@/components/common/icon';
import { downloadAndProgress, isMobile } from '@/biz/tool';
import { copyToClipboard } from '@/utils';
import { CopyToClipboard } from '@/components/common/copyToClipboard';
import CircularProgress, {
  CircularProgressProps
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { isOrderAvailable } from '@/biz/order';
import { normalizePrice } from '@/utils/normalizePrice';
import Tooltip from '@material-ui/core/Tooltip';
import { ProductType } from '../web/config';

const BrandResources: React.FC<{ data: any }> = ({ data }) => {
  const { order, campaign, product } = data;
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState<any[]>([]);
  const [progress, setProgress] = useState<any>([]);

  useEffect(() => {
    let arr: any[] = [];
    if (Array.isArray(campaign?.mustDoFiles)) {
      arr = arr.concat(campaign?.mustDoFiles);
    }
    if (Array.isArray(campaign?.mustNotDoFiles)) {
      arr = arr.concat(campaign?.mustNotDoFiles);
    }
    setAssets(arr);
  }, [campaign?.mustDoFiles, campaign?.mustNotDoFiles]);

  useEffect(() => {
    if (order) {
      setLoading(false);
    }
  }, [order]);

  const updateProgress = (index: number, val: number) => {
    if (isNaN(val)) return;
    const newProgress = [...progress];
    newProgress[index] = val === 100 ? -1 : val;
    console.log('newProgress', newProgress);

    setProgress(newProgress);
  };

  const getDownloadLink = async (key: any, index: number) => {
    updateProgress(index, 2);
    try {
      const {
        data: { code, message, data }
      } = await axiosInstance.post(
        `/campaign/${order.campaignId}/downloadLink`,
        {
          key
        }
      );
      // updateProgress(index, 30);

      const url = data?.presignedUrl;
      if (!url) {
        throw new Error(message || 'Failed to get download link');
      }

      downloadAndProgress(url, key, (val: number) => {
        // console.log(index, 'val', val);
        updateProgress(index, val);
      });
    } catch (e) {
      console.error(e);
      toast.error('Download failed.');
    }
  };

  const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number }
  ) => {
    return (
      <div className="relative w-full h-full">
        <CircularProgress
          className="absolute mt-[2px] ml-[1px]"
          size={36}
          variant="determinate"
          color="inherit"
          {...props}
        />
        <div className="absolute top-1/2 left-1/2  translate-x-[-50%] translate-y-[-50%]">
          <Typography variant="caption" component="div" color="textSecondary">
            {Math.round(props.value)}%
          </Typography>
        </div>
      </div>
    );
  };

  if (loading) return <BrandResourcesSkeleton />;

  if (!isOrderAvailable(order))
    return (
      <>
        <div className="text-[26px] font-semibold font-['Geist'] leading-10 mt-10">
          Brand resources
        </div>
        <div className="w-full p-2.5 flex-col justify-center items-center gap-4 inline-flex mt-4">
          <EmptyBrandIcon />
          <div className=" text-center text-[#8b8d98] text-xs font-normal font-['Inter'] leading-none">
            Brand assets (e.g., promotional link, trial account credentials){' '}
            <br />
            will be provided upon approval of your application.
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="text-[26px] font-semibold font-['Geist'] leading-10 mt-10">
        Brand resources
      </div>
      <div className="flex flex-col gap-2 mt-4">
        {campaign?.brandAsset && (
          <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex gap-[12px]">
            <div
              className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal flex-shrink-0
                ${isMobile() ? 'w-24' : ''}`}
            >
              Brand assets
            </div>
            <div
              className={`flex items-center text-[#0C67E6] overflow-hidden
                ${isMobile() ? 'max-w-[188px]' : ''}`}
            >
              <a
                href={campaign?.brandAsset}
                target="_blank"
                rel="noreferrer"
                className="hover:underline truncate"
              >
                {campaign?.brandAsset || '-'}
              </a>
              {campaign?.brandAsset && (
                <CopyToClipboard
                  onClick={() => {
                    copyToClipboard(campaign?.brandAsset);
                    toast.success('Brand assets copied.');
                  }}
                />
              )}
            </div>
          </div>
        )}
        {product?.productType === ProductType.PHYSICAL_PRODUCT &&
          product?.physicalProductVideoUrl && (
            <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex gap-[12px]">
              <div
                className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal flex-shrink-0
                ${isMobile() ? 'w-24' : ''}`}
              >
                Video assets
              </div>
              <div
                className={`flex items-center text-[#0C67E6] overflow-hidden
                ${isMobile() ? 'max-w-[188px]' : ''}`}
              >
                <a
                  href={product?.physicalProductVideoUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline truncate"
                >
                  {product?.physicalProductVideoUrl || '-'}
                </a>
                {product?.physicalProductVideoUrl && (
                  <CopyToClipboard
                    onClick={() => {
                      copyToClipboard(product?.physicalProductVideoUrl);
                      toast.success('Video assets copied.');
                    }}
                  />
                )}
              </div>
            </div>
          )}
        {order?.utmLink && (
          <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
            <div
              className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal
                ${isMobile() ? 'w-24' : ''}`}
            >
              Promotional link
            </div>
            <div
              className={`flex items-center text-[#0C67E6] 
                ${isMobile() ? 'max-w-[188px]' : ''}`}
            >
              <a
                href={order?.utmLink}
                target="_blank"
                rel="noreferrer"
                className="hover:underline truncate"
              >
                {order?.utmLink || '-'}
              </a>
              {order?.utmLink && (
                <CopyToClipboard
                  onClick={() => {
                    copyToClipboard(order?.utmLink);
                    toast.success('Promotional link copied.');
                  }}
                />
              )}
            </div>
          </div>
        )}
        {/* username */}
        {order?.credentialForInfluencer?.username ? (
          <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
            <div
              className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal
                ${isMobile() ? 'w-24' : ''}`}
            >
              Trial account username
            </div>
            <div
              className={`flex items-center ${isMobile() ? 'max-w-[188px]' : ''}`}
            >
              <div className="w-full truncate">
                {order?.credentialForInfluencer?.username}
              </div>
              {order?.credentialForInfluencer?.username && (
                <CopyToClipboard
                  onClick={() => {
                    copyToClipboard(order?.credentialForInfluencer?.username);
                    toast.success('Trial account username copied.');
                  }}
                />
              )}
            </div>
          </div>
        ) : null}
        {/* password */}
        {order?.credentialForInfluencer?.password ? (
          <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
            <div
              className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal
                ${isMobile() ? 'w-24' : ''}`}
            >
              Trial account password
            </div>
            <div
              className={`flex items-center ${isMobile() ? 'max-w-[188px]' : ''}`}
            >
              <div className="w-full truncate">
                {order?.credentialForInfluencer?.password}
              </div>
              {order?.credentialForInfluencer?.password && (
                <CopyToClipboard
                  onClick={() => {
                    copyToClipboard(order?.credentialForInfluencer?.password);
                    toast.success('Trial account password copied.');
                  }}
                />
              )}
            </div>
          </div>
        ) : null}
        {/* username */}
        {order?.credential?.username ? (
          <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
            <div
              className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal
                ${isMobile() ? 'w-24' : ''}`}
            >
              Trial account username
            </div>
            <div
              className={`flex items-center ${isMobile() ? 'max-w-[188px]' : ''}`}
            >
              <div className="w-full truncate">
                {order?.credential?.username}
              </div>
              {order?.credential?.username && (
                <CopyToClipboard
                  onClick={() => {
                    copyToClipboard(order?.credential?.username);
                    toast.success('Trial account username copied.');
                  }}
                />
              )}
            </div>
          </div>
        ) : null}
        {/* password */}
        {order?.credential?.password ? (
          <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
            <div
              className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal
                ${isMobile() ? 'w-24' : ''}`}
            >
              Trial account password
            </div>
            <div
              className={`flex items-center ${isMobile() ? 'max-w-[188px]' : ''}`}
            >
              <div className="w-full truncate">
                {order?.credential?.password}
              </div>
              {order?.credential?.password && (
                <CopyToClipboard
                  onClick={() => {
                    copyToClipboard(order?.credential?.password);
                    toast.success('Trial account password copied.');
                  }}
                />
              )}
            </div>
          </div>
        ) : null}
        {/* couponCode  */}
        {order?.couponCode ? (
          <div className="self-stretch p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] justify-between items-center inline-flex">
            <div
              className={`text-[#60646c] text-base font-normal font-['Inter'] leading-normal
                ${isMobile() ? 'w-24' : ''}`}
            >
              Coupon code
            </div>
            <div
              className={`flex items-center ${isMobile() ? 'max-w-[188px]' : ''}`}
            >
              <div className="w-full truncate">{order?.couponCode}</div>
              {order?.couponCode && (
                <CopyToClipboard
                  onClick={() => {
                    copyToClipboard(order?.couponCode);
                    toast.success('Coupon code copied.');
                  }}
                />
              )}
            </div>
          </div>
        ) : null}

        {+campaign?.membershipFee > 0 && (
          <div className="h-28 p-4 bg-[#fcfcfd] rounded-lg border border-[#f3f3f3] flex-col justify-center items-start gap-2 inline-flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
                Membership fee
              </div>
              <div className="flex-col justify-start items-end inline-flex">
                <div className="justify-end items-center gap-2 inline-flex">
                  <div className="text-right text-[#1c2024] text-base font-medium font-['Inter'] leading-normal">
                    {normalizePrice(campaign?.membershipFee)}
                  </div>
                  <div className="w-4 h-4 relative">
                    <Tooltip title="The membership fee will be delivered to you along with your upfront payment.">
                      <span>
                        <WarnOutlineIcon className="white" />
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch text-[#1c2024] text-base font-medium font-['Inter'] leading-normal">
              {campaign?.membershipNote}
            </div>
          </div>
        )}

        <div className="w-full flex flex-col justify-start items-start gap-4">
          {assets.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="w-full flex items-center gap-4 rounded-lg bg-[#F9F9FB] px-8 py-4"
              >
                <div className="w-10 h-10">
                  {
                    [
                      <AssetsAnnexIcon />,
                      <CardAnnexIcon />,
                      <BrandAnnexIcon />
                    ][index % 3]
                  }
                </div>
                <div className="flex-1 w-full">
                  <div>{item}</div>
                </div>

                <div
                  className="relative w-10 h-10 cursor-pointer rounded-lg border border-[#E8E8EC] hover:shadow-md"
                  onClick={() => getDownloadLink(item, index)}
                >
                  {!progress[index] || progress[index] === -1 ? (
                    <DownloadIcon className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                  ) : (
                    <CircularProgressWithLabel value={progress[index]} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BrandResources;

const BrandResourcesSkeleton = () => {
  return (
    <>
      <div className="text-[26px] font-semibold font-['Geist'] leading-10 mt-10">
        Brand resources
      </div>
      <div className="flex flex-col gap-2">
        <div className="h-[20px] w-[203px] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[203px] bg-gray-200 rounded animate-pulse" />
        <div className="h-[20px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
      </div>
    </>
  );
};
