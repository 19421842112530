import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { OrderStatus } from '@/interface/order';
import { isMobile } from '@/biz/tool';
import ModalAddress from './ModalAddress';
import { useUser } from '@clerk/clerk-react';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { useClerk, useAuth } from '@clerk/clerk-react';
import {
  getClerkSignInRedirectUrl,
  getClerkSignupRedirectUrlPayload,
  getClerkSignupRedirectUrl
} from '@/biz/tool';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import CircularProgress from '@material-ui/core/CircularProgress';
import { notMatch } from '@/biz/tool';
import toast from 'react-hot-toast';
import { AuthorizeErrorIcon } from '@/components/common/icon';
import { useOrderListStore } from '@/hooks/useOrderListStore';

const ModalShowAnInterest: React.FC<{ data: any }> = ({ data }) => {
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn } = useAuth();
  const { order, campaign } = data;
  const [showAddressModal, setShowAddressModal] = useState(false);
  const { user: clerkUser } = useUser();
  const {
    setShowModalAnalysis,
    setShowModalSelectInvitedOrderByNoMatch,
    setShowModalConfirmDealPrice
  } = useWebModalStore();
  const { reQueryOrderListData } = useOrderListStore();
  const { detailData, reQueryDetailData, isLoadingData } = useDetailDataStore();

  if (![OrderStatus.Invited].includes(order?.status)) {
    return null;
  }

  const handleClerkSignIn = () => {
    const payload: any = getClerkSignupRedirectUrlPayload(detailData);

    if (!detailData?.influencer?.isAuthorized) {
      payload.urlShowModalAnalysis = true;
    } else {
      payload.urlShowModalConfirmDealPrice = true;
    }

    openSignIn({
      withSignUp: true,
      forceRedirectUrl: getClerkSignInRedirectUrl({ payload }),
      fallbackRedirectUrl: getClerkSignInRedirectUrl({ payload }),
      // 注册
      signUpForceRedirectUrl: getClerkSignupRedirectUrl({ payload }),
      signUpFallbackRedirectUrl: getClerkSignupRedirectUrl({ payload }),
      //
      appearance: {
        elements: {
          // 按钮隐藏
          modalCloseButton: 'hidden',
          // 蒙版不可点击
          modalBackdrop: 'pointer-events-none',
          // 内容可点击
          modalContent: 'pointer-events-auto'
        }
      }
    });
  };

  return (
    <>
      <>
        <Button
          disabled={isLoadingData}
          variant="contained"
          className={`mui-btn-primary ${isMobile() ? '!h-10' : ''}`}
          onClick={async () => {
            const isNotMatch = notMatch({ detailData });
            if (detailData?.user && isNotMatch) {
              toast.error('Please log in with the same account.');
              return;
            }
            if (isLoaded && !isSignedIn) {
              if (detailData?.influencer?.isAuthorized) {
                await reQueryDetailData({
                  orderId: detailData?.order?._id,
                  validate: true,
                  clearDateAndUpdate: false
                });
              }
              handleClerkSignIn();
              return;
            }
            // 判断是否需要授权分析

            if (!detailData?.influencer?.isAuthorized) {
              setShowModalAnalysis(true);
            } else {
              // 已经授权分析，则直接选择价格
              const newDetailData = await reQueryDetailData({
                orderId: detailData?.order?._id,
                validate: true,
                clearDateAndUpdate: false
              });

              const needUpdate =
                newDetailData?.order?.validateResult?.need_update;
              const updateType =
                newDetailData?.order?.validateResult?.update_type;

              if (needUpdate && updateType === 'cancel_order') {
                // 请求 invited 订单
                setShowModalSelectInvitedOrderByNoMatch(true);
                await reQueryOrderListData();
                // end 请求 invited 订单
              } else {
                setShowModalConfirmDealPrice(true);
              }
            }
          }}
        >
          {isLoadingData ? (
            <div className="w-6 h-6 mr-2">
              <CircularProgress
                color="inherit"
                className="!w-6 !h-6"
                disableShrink
              />
            </div>
          ) : null}
          {isLoadingData
            ? "I'm interested..."
            : clerkUser?.id
              ? "I'm interested"
              : "Sign in and I'm interested"}
        </Button>

        {/*  */}
        {/* // TODO: 实体部分暂无UI */}
        {/*  */}
        {/* 实体广告主的达人需要填写地址 */}
        <ModalAddress
          isOpen={showAddressModal}
          setIsOpen={setShowAddressModal}
          setShowAnInterestOpen={setShowModalConfirmDealPrice}
          campaign={campaign}
        />
      </>
    </>
  );
};

export default ModalShowAnInterest;
