import React, { useEffect, useState } from 'react';
import { OrderStatus } from '../../interface/order';
import { HelpIcon, TimeLineDot } from '@/components/common/icon';
import {
  PlatformIconComponent,
  getPlatformName,
  getPlatformVideoContentType
} from '@/biz/platform';
import {
  getTimezoneName,
  getDuration,
  formatDateWithSuffix,
  formattedDate
} from '@/biz/time';
import ModalShowAnInterest from '@/components/web/components/ModalShowAnInterest';
import ModalNotInterest from '@/components/web/components/ModalNotInterest';
import ModalPostponeTimeline from '@/components/web/components/ModalPostponeTimeline';
import { isMobile } from '@/biz/tool';
import Tooltip from '@material-ui/core/Tooltip';
import TooltipFirstOrder from './components/TooltipFirstOrder';
import TooltipFirstDealMoney from './components/TooltipFirstDealMoney';
import { withStyles } from '@material-ui/core/styles';
import TooltipFirstDealMoneyMobile from './components/TooltipFirstDealMoneyMobile';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import { getShowScript } from '@/biz/order';
import { PostTimeType, ProductType, ProductPhysicalSelectEnum } from './config';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import PhysicalPrice from './components/PhysicalPrice';
import ProductLogo from './components/ProductLogo';
import PriceFieldsComponent from '@/components/web/components/PriceFieldsComponent';

export const BlackTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#1c2024',
    color: '#ffffff',
    fontSize: '12px',
    padding: '8px 12px',
    borderRadius: '6px'
  },
  arrow: {
    color: '#1c2024'
  }
}))(Tooltip);

const firstPostDeadline = (
  hours: number | null,
  createdAt?: string | Date | null
) => {
  if (createdAt) return formatDateWithSuffix(createdAt);
  if (hours) return `${getDuration(hours)}  after script approval`;
  return '-';
};

const initialScriptDraftDeadline = (
  hours: number | null,
  createdAt?: string | Date | null
) => {
  if (createdAt) return formatDateWithSuffix(createdAt);
  if (hours) return `${getDuration(hours)} after deal established`;
  return '-';
};

interface Props {
  data: any;
}

const InfluencerAndTimeline: React.FC<Props> = ({ data }) => {
  const { isLoadingData } = useDetailDataStore();
  const { order, product, influencer, timeline } = data;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const priceFields = order?.priceFields;
  const interestedPriceFields = order?.interestedPriceFields;

  const hasIntegratedVideo =
    Boolean(order?.priceFields?.length > 1) && !interestedPriceFields;

  const showTwoPrice = Boolean(
    // 有两个价格了 且 没有提交感兴趣价格
    interestedPriceFields?.length === 0 && priceFields?.length > 1
  );

  const { gbPricingSchemaFlag } = useMultiplePricesStore();

  return (
    <div
      className={`relative pb-6 bg-white rounded-2xl flex-col justify-start items-start gap-4 inline-flex
                ${isMobile() ? 'w-full ' : 'pt-8 px-6 w-[405px]'}
                `}
    >
      {/* 首单提示 */}
      {!isMobile() && <TooltipFirstOrder />}
      {isMobile() && order?.displayStatus === 'Under_script_production' && (
        <TooltipFirstOrder />
      )}
      {/*  */}
      {product?.name || influencer?.name ? (
        <div className="text-[#1c2024] w-full text-[23px] font-semibold font-['Plus Jakarta Sans'] leading-9">
          {product?.name} x {influencer?.name} brand deal
        </div>
      ) : null}

      {/* 产品 */}
      <div className="w-full justify-between items-center inline-flex">
        <div className="justify-start items-center gap-4 flex">
          <ProductLogo src={product?.logo} />
          <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className="w-full text-xl font-semibold font-['Geist'] leading-loose">
              {/* {!isLoadingData ? ( */}
              {product?.name}
              {/* ) : (
                <div className="flex flex-col gap-2 w-full">
                  <div className="h-[24px] w-[203px] bg-gray-200 rounded animate-pulse" />
                  <div className="h-[16px] w-[80.3%] bg-gray-200 rounded animate-pulse" />
                </div>
              )} */}
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href={product?.website}
              className="text-[#0c67e6] text-sm font-normal font-['Inter'] leading-tight hover:underline break-all"
            >
              {product?.website}
            </a>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="flex flex-col justify-between w-full px-2 py-4 border-t border-b border-[#e5e5e5] gap-4">
        <div className="justify-between items-center gap-1.5 flex">
          <div className="text-[#60646c] text-sm font-medium font-['Inter'] leading-tight">
            Platform
          </div>
          <div className="flex items-center gap-2 text-[#0c67e6] text-sm font-['Inter'] leading-tight">
            <BlackTooltip
              title={getPlatformName(order?.platform)}
              placement={'top-end'}
              arrow
            >
              <div className="">
                <PlatformIconComponent
                  platform={order?.platform}
                  className="w-4 h-4"
                />
              </div>
            </BlackTooltip>
            {influencer?.username
              ? influencer.username.startsWith('@')
                ? influencer.username
                : `@${influencer.username}`
              : ''}
          </div>
        </div>

        <div className="justify-between items-center gap-1.5 flex">
          <div className="text-[#60646c] text-sm font-medium font-['Inter'] leading-tight">
            Content type
          </div>
          {/* <DedicatedVideoIcon className="w-5 h-5" /> */}

          {order?.interestedPriceFields?.length > 0 ? (
            <div className="flex justify-center items-center text-nowrap text-xs gap-1">
              {interestedPriceFields?.map((el: any, index: number) => {
                return (
                  <React.Fragment key={`${el.contentType}-${index}`}>
                    {index > 0 ? (
                      <div className="text-nowrap text-xs">/</div>
                    ) : null}
                    {getPlatformVideoContentType({
                      platform: order?.platform,
                      contentType: el.contentType
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          ) : priceFields?.length > 0 ? (
            <div className="flex justify-center items-center text-nowrap text-xs gap-1">
              {priceFields?.map((el: any, index: number) => {
                return (
                  <React.Fragment key={`${el.contentType}-${index}`}>
                    {index > 0 ? (
                      <div className="text-nowrap text-xs">/</div>
                    ) : null}
                    {getPlatformVideoContentType({
                      platform: order?.platform,
                      contentType: el.contentType
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div className="text-nowrap text-xs">
              {getPlatformVideoContentType({ platform: order?.platform })}
            </div>
          )}
        </div>
      </div>
      {/* title */}
      <div className="flex w-full justify-between items-center">
        <div className="text-xl font-semibold font-['Geist'] leading-7 flex items-center gap-2">
          <div className="text-[#1c2024] text-[19px] font-semibold font-['Plus Jakarta Sans'] leading-[30px]">
            Deal price
          </div>
          {/* 首单 bonus */}
          {/* {order?.isFirstOrder ? (
            <div className="h-[22px] px-2.5 py-0.5 bg-[#5a31f0] rounded-full flex-col justify-center items-center gap-2.5 inline-flex">
              <div className="justify-center items-center gap-1 inline-flex">
                <div className="text-neutral-50 text-xs font-semibold font-['Inter']">
                  +10%
                </div>
                <UpMoneyIcon className="w-[9px] h-[10px] mb-[2px]" />
                <div className="text-neutral-50 text-xs font-semibold font-['Inter']">
                  first order bonus
                </div>
              </div>
            </div>
          ) : null} */}
        </div>

        {isMobile() ? (
          <BlackTooltip
            title="Aha will match you with the best price based on your audience’s location. Click “Show Interest” to start negotiating with us."
            placement={'bottom-end'}
            PopperProps={{
              disablePortal: true
            }}
            onClose={isMobile() ? () => setTooltipOpen(false) : undefined}
            open={tooltipOpen}
            arrow
          >
            <div
              className="cursor-pointer"
              onClick={() => setTooltipOpen(true)}
            >
              <HelpIcon className="w-5 h-5 text-[#5b3de9]" />
            </div>
          </BlackTooltip>
        ) : (
          <BlackTooltip
            title="Aha will match you with the best price based on your audience’s location. Click “Show Interest” to start negotiating with us."
            placement={'top-end'}
            arrow
          >
            <div
              className="cursor-pointer"
              onClick={() => setTooltipOpen(true)}
            >
              <HelpIcon className="w-5 h-5 text-[#5b3de9]" />
            </div>
          </BlackTooltip>
        )}
      </div>
      {/* 定价 */}
      <PriceFieldsComponent />
      {/*  */}
      <ShowInterestButtonBox data={data} />
      {/* 实体价格 */}
      {product?.productType === ProductType.PHYSICAL_PRODUCT &&
        product?.physicalProductShipType === ProductPhysicalSelectEnum.ship && (
          <PhysicalPrice price={product?.physicalProductCashValue} />
        )}
      {/*  */}
      <div className="text-[#60646c] text-xs font-semibold font-['Geist'] leading-normal">
        TIMELINE
      </div>
      <TimeLineItem data={data} />
      {/* 首单 bonus up 10 percent */}
      {order?.isFirstOrder ? <TooltipFirstDealMoney /> : null}
    </div>
  );
};

export default InfluencerAndTimeline;

// finalPostDeadline
const finalPostDeadline = (
  hours: number | null,
  createdAt?: string | Date | null,
  postTimeType?: string,
  postTimeRange?: any
) => {
  if (postTimeType === PostTimeType.FIXED && postTimeRange?.length === 2) {
    return `${formattedDate(postTimeRange?.[0])} to ${formattedDate(postTimeRange?.[1])}`;
  }
  if (createdAt) return formatDateWithSuffix(createdAt);
  if (hours) return `${getDuration(hours)} after draft approval`;
  return '-';
};
const TimeLineItem = ({ data }: { data: any }) => {
  const { timeline, order, campaign } = data;
  const [timelines, setTimelines] = useState<any[]>([]);
  const showScript = getShowScript(order);

  useEffect(() => {
    if (order?.status === OrderStatus.Invited) {
      setTimelines(lines);
    } else {
      setTimelines(lines.slice(1, 99));
    }
  }, [order]);

  const lines = [
    { label: 'Show Interest', value: 'Today' },
    {
      hidden: !showScript,
      label: 'Script due (optional)',
      value: (
        <>
          <div className="">
            {initialScriptDraftDeadline(
              timeline?.initialScriptDraft?.maxWaitInHours,
              timeline?.initialScriptDraft?.deadlineTs
            )}
          </div>
          {timeline?.initialScriptDraft?.deadlineTs ? (
            <div className="text-[#8b8d98]">
              ({getTimezoneName(timeline?.initialScriptDraft?.maxWaitInHours)})
            </div>
          ) : null}
        </>
      )
    },
    {
      label: '1st draft video due',
      value: (
        <>
          <div className="">
            {showScript
              ? firstPostDeadline(
                  timeline?.firstVideoDraftDue?.maxWaitInHours,
                  timeline?.firstVideoDraftDue?.deadlineTs
                )
              : initialScriptDraftDeadline(
                  timeline?.firstVideoDraftDue?.maxWaitInHours,
                  timeline?.firstVideoDraftDue?.deadlineTs
                )}
          </div>
          {timeline?.firstVideoDraftDue?.deadlineTs ? (
            <div className="text-[#8b8d98] ml-1">
              ({getTimezoneName(timeline?.firstVideoDraftDue?.maxWaitInHours)})
            </div>
          ) : null}
        </>
      )
    },
    {
      label: 'Final link due',
      value: (
        <>
          <div className="">
            {finalPostDeadline(
              timeline?.finalPostDeadline?.maxWaitInHours,
              timeline?.finalPostDeadline?.deadlineTs,
              campaign?.postTimeType,
              campaign?.postTimeRange
            )}
          </div>
          {timeline?.finalPostDeadline?.deadlineTs ? (
            <div className="text-[#8b8d98] ml-1">
              ({getTimezoneName(timeline?.finalPostDeadline?.maxWaitInHours)})
            </div>
          ) : null}
        </>
      )
    }
  ];

  return (
    <div className="flex flex-col">
      {timelines.map((el, index) => {
        const isLastOne = index + 1 === timelines.length;
        return (
          <div
            className="justify-start items-center inline-flex relative"
            key={el.label}
            style={{ display: el.hidden ? 'none' : 'flex' }}
          >
            {isLastOne ? (
              ''
            ) : (
              <div className="w-[42px] h-full flex justify-center absolute top-2 flex-shrink-0">
                <div className="w-[1px] h-full bg-[#d9d9e0] absolute top-[3px]"></div>
              </div>
            )}
            <div className="w-[42px] flex justify-center self-start mt-[6px] z-10 bg-white flex-shrink-0">
              <TimeLineDot />
            </div>
            <div
              className={`${isLastOne ? '' : 'mb-4'} flex-col justify-start items-start gap-1 inline-flex`}
            >
              <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-none">
                {el.label}
              </div>
              <div className="text-[#1c2024] text-sm font-medium font-['Inter'] leading-tight flex flex-wrap gap-1">
                {el.value}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ShowInterestButtonBox = ({ data }: { data: any }) => {
  const { order } = data;
  if (![OrderStatus.Invited].includes(order?.status)) {
    return null;
  }

  return (
    <div
      className={`w-full flex mt-2
    ${isMobile() ? 'fixed bottom-0 left-0 bg-white z-[999] p-4 flex-row-reverse border-t border-solid border-[##EE8EC] gap-4' : 'flex-col'}`}
    >
      {isMobile() && order?.isFirstOrder ? (
        <TooltipFirstDealMoneyMobile />
      ) : null}

      <ModalShowAnInterest data={data} />
      <ModalNotInterest />
      <ModalPostponeTimeline />
    </div>
  );
};
