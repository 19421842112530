import React, { useEffect, useState } from 'react';
import { GoogleIconWhite } from '@/components/common/icon';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { OrderStatus } from '@/interface/order';
import {
  isMobile,
  getClerkSignInRedirectUrl,
  getClerkSignupRedirectUrlPayload,
  getClerkSignupRedirectUrl
} from '@/biz/tool';
import { SignInButton, SignedOut } from '@clerk/clerk-react';

interface Props {
  desc: any;
}

export const LoginDetailBottomButton: React.FC<Props> = ({ desc }) => {
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginButtonDesc, setLoginButtonDesc] = useState('');

  const { detailData } = useDetailDataStore();

  useEffect(() => {
    if (detailData?.order?.status === OrderStatus.Invited) {
      setLoginButtonDesc('to join the campaign');
    }
  }, [detailData]);

  return (
    <div className="">
      <SignedOut>
        <SignInButton
          withSignUp={true}
          forceRedirectUrl={getClerkSignInRedirectUrl({
            payload: getClerkSignupRedirectUrlPayload(detailData)
          })}
          fallbackRedirectUrl={getClerkSignInRedirectUrl({
            payload: getClerkSignupRedirectUrlPayload(detailData)
          })}
          // 注册
          signUpForceRedirectUrl={getClerkSignupRedirectUrl({
            payload: getClerkSignupRedirectUrlPayload(detailData)
          })}
          signUpFallbackRedirectUrl={getClerkSignupRedirectUrl({
            payload: getClerkSignupRedirectUrlPayload(detailData)
          })}
          // mode="modal"
        >
          <div
            className={`min-h-[42px] px-4 py-2 bg-[#5a31f0] rounded-[999px] justify-center items-center gap-3 inline-flex cursor-pointer
            ${isMobile() ? 'w-[330px]' : 'w-[358px]'}`}
          >
            <GoogleIconWhite className="w-6 h-6" />
            <div className="flex-col justify-center items-start inline-flex">
              <div className="text-white text-base font-semibold font-['Geist'] leading-normal text-nowrap">
                {loginLoading
                  ? 'Logging in with Google...'
                  : 'Login with Google'}
              </div>
              {loginButtonDesc ? (
                <div className="text-white/60 text-xs font-normal font-['Inter']">
                  {loginButtonDesc}
                </div>
              ) : null}
            </div>
          </div>
        </SignInButton>
      </SignedOut>
    </div>
  );
};
