// 文件废弃
import React, { useState } from 'react';
import { getDuration } from '@/biz/time';
import Radio from '@/components/Radio/index';
import { InfluencerAgreement } from '@/components/campaign/influencerAgreement';
import { isMobile } from '@/biz/tool';
import Modal from '@/components/web/components/Modal';
import BottomDrawer from './BottomDrawer';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import {
  JoinDrawEmaiIcon,
  JoinDrawClockIcon,
  JoinDrawFrontPaymentIcon,
  ScriptIcon,
  DedicatedVideoFilledIcon
} from '../../common/icon';
import { getFrontPaymentRate } from '../../../pages/campaign/config';
import styles from './interestContent.module.less';
import { AdsPlatform } from '@/interface/order';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import { normalizePrice } from '@/utils/normalizePrice';

interface Props {
  setShowAnInterestOpen?: Function;
}

const initialScriptDraftDeadline = (hours: number | null) => {
  if (hours) return getDuration(hours);
  return '-';
};

const InterestContent: React.FC<Props> = ({ setShowAnInterestOpen }) => {
  const { selectedPriceFields, inputPrice } = useMultiplePricesStore();

  const { detailData } = useDetailDataStore();
  const { order, timeline } = detailData;
  const rate = getFrontPaymentRate(order?.prepaymentRate);
  const {
    skipScript,
    setSkipScript,
    isAgree,
    setIsAgree,
    isShowAgreement,
    setIsShowAgreement
  } = useWebModalStore();

  return (
    <div className={isMobile() ? styles.container : styles.webContainer}>
      <div
        className={`
          ${isMobile() ? 'gap-2 flex flex-col' : 'gap-8 flex'}
        }`}
        style={{
          display: order?.platform === AdsPlatform.Youtube ? 'flex' : 'none'
        }}
      >
        <div
          className={`w-full p-4 bg-white rounded-2xl border  flex flex-col gap-4 cursor-pointer
            ${!skipScript ? 'border-[#5a31f0]' : 'border-[#e0e0e0]'}
            ${isMobile() ? '!p-3' : ''}
            `}
          onClick={() => {
            if (skipScript) {
              setSkipScript(false);
            }
          }}
        >
          <div className="flex justify-between items-center gap-2">
            <ScriptIcon
              className={`w-6 h-6 ${!skipScript ? 'text-[#5B3DE9]' : ''}`}
            />
            <Radio checked={!skipScript} />
          </div>
          <div className="flex flex-col gap-2">
            <div
              className="text-[#1c2024] font-semibold font-['Plus Jakarta Sans'] leading-normal"
              style={{
                fontSize: isMobile() ? '14px' : '16px'
              }}
            >
              I would like to create a script.
            </div>
            <div
              className="text-[#1c2024] font-normal font-['Plus Jakarta Sans'] leading-snug"
              style={{
                fontSize: isMobile() ? '12px' : '14px'
              }}
            >
              Creating a script for your video ensures that key concepts and
              creative directions are aligned with your brand.
            </div>
          </div>
        </div>
        <div
          className={`w-full p-4 bg-white rounded-2xl border  flex flex-col gap-4 cursor-pointer
            ${skipScript ? 'border-[#5a31f0]' : 'border-[#e0e0e0]'}
            ${isMobile() ? '!p-3' : ''}
            `}
          onClick={() => {
            if (!skipScript) {
              setSkipScript(true);
            }
          }}
        >
          <div className="flex justify-between items-center gap-2">
            <DedicatedVideoFilledIcon
              className={`w-6 h-6 ${skipScript ? 'text-[#5B3DE9]' : ''}`}
            />
            <Radio checked={skipScript} />
          </div>
          <div className="flex flex-col gap-2">
            <div
              className="text-[#1c2024] font-semibold font-['Plus Jakarta Sans'] leading-normal"
              style={{
                fontSize: isMobile() ? '14px' : '16px'
              }}
            >
              I would like to skip the script.
            </div>
            <div
              className="text-[#1c2024] font-normal font-['Plus Jakarta Sans'] leading-snug"
              style={{
                fontSize: isMobile() ? '12px' : '14px'
              }}
            >
              You can forgo the scripting process and provide your video draft
              directly to the brand for feedback.
            </div>
          </div>
        </div>
      </div>
      <div
        className="h-[1px] w-full bg-[#e8e8ec] mt-6 mb-4"
        style={{
          display: order?.platform === AdsPlatform.Youtube ? 'flex' : 'none'
        }}
      ></div>
      <div className={styles.confirmDesc}>
        <div className={styles.li}>
          <JoinDrawEmaiIcon className={styles.joinDrawIcon} />
          <span>
            We'll review your application and send results within 1-2 days.
          </span>
        </div>
        <div className={styles.li}>
          <JoinDrawClockIcon className={styles.joinDrawIcon} />
          <div>
            {order?.platform === AdsPlatform.Youtube ? (
              <>
                <span>If accepted, submit your script draft within </span>
                <span className={styles.timeText}>
                  {initialScriptDraftDeadline(
                    timeline?.initialScriptDraft?.maxWaitInHours
                  )}{' '}
                </span>
                of approval.
              </>
            ) : (
              <>
                <span>If accepted, submit your draft within </span>
                <span className={styles.timeText}>
                  {initialScriptDraftDeadline(
                    timeline?.firstVideoDraft?.maxWaitInHours
                  )}{' '}
                </span>
                of approval.
              </>
            )}
          </div>
        </div>
        <div className={styles.li}>
          <JoinDrawFrontPaymentIcon className={styles.joinDrawIcon} />
          <span>
            You'll receive{' '}
            <span style={{ fontWeight: '600', color: '#1C2024' }}>
              {rate}
              {/* ({normalizePrice(order?.prePaymentAmount)}) */}
            </span>{' '}
            after your application is approved and another{' '}
            <span style={{ fontWeight: '600', color: '#1C2024' }}>
              {rate}
              {/* ({normalizePrice(order?.price - order?.prePaymentAmount)}) */}
            </span>{' '}
            within one week of your post going live.
          </span>
        </div>
      </div>
      {!isMobile() ? (
        <div className={styles.agree}>
          <Radio
            checked={isAgree}
            onClick={() => {
              setIsAgree(!isAgree);
            }}
          />
          <span className="ml-1">I consent to Aha's</span>
          <span
            className={styles.linkText}
            onClick={() => setIsShowAgreement(!isShowAgreement)}
          >
            Creator Agreement
          </span>
          .
        </div>
      ) : null}
      {/* {JSON.stringify(selectedPriceFields)} */}
      <div className="w-full h-[1px] my-4 bg-[#e8e8ec]"></div>
      <div className="flex w-full justify-between items-center">
        <div className="text-[#1c2024] text-sm font-semibold font-['Plus Jakarta Sans'] leading-normal">
          Deal price
        </div>
        <div className="flex flex-col gap-2">
          {selectedPriceFields.length &&
            selectedPriceFields?.map((el: any) => {
              const { price, originPrice } = el;
              return (
                <div className="flex gap-2">
                  {inputPrice ? (
                    <div className="text-[#1c2024] text-sm font-semibold font-['Plus Jakarta Sans'] leading-normal">
                      {normalizePrice(inputPrice)}
                    </div>
                  ) : null}

                  <div
                    className={`text-sm font-semibold font-['Plus Jakarta Sans'] leading-normal
                    ${inputPrice ? 'line-through text-[#8b8d98]' : 'text-[#1c2024]'}
                    `}
                  >
                    {/* 如果没有输入价格，则显示为黑色无横线 */}
                    {!isNaN(originPrice) ? normalizePrice(originPrice) : ''}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {isMobile() ? (
        <BottomDrawer
          visible={isShowAgreement}
          isShowFooter={false}
          isShowHeader={false}
        >
          <InfluencerAgreement setIsShowAgreement={setIsShowAgreement} />
        </BottomDrawer>
      ) : (
        <Modal
          visible={isShowAgreement}
          isShowFooter={false}
          isShowHeader={false}
        >
          <InfluencerAgreement setIsShowAgreement={setIsShowAgreement} />
        </Modal>
      )}
    </div>
  );
};

export default InterestContent;
