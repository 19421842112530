import React, { useEffect, useState } from 'react';
import styles from './connect.module.less';
import { isMobile } from '@/biz/tool';
import ModalSelectPlatformContent from '@/components/web/components/ModalSelectPlatformContent';

const ConnectPage: React.FC = () => {
  return (
    <div
      className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div className={styles.title}>
            You're one step from landing brand deals!
          </div>
          <div className={styles.desc}>
            Connect your socials to your Aha account so that we can find
            personalized brand deals for your channel.{' '}
            <a
              className={styles.doc}
              target="_blank"
              rel="noreferrer"
              href="https://chartreuse-wrinkle-697.notion.site/Aha-Docs-11104daded68803fb924f34bbac22a76#13404daded68803a8657fd3df2d0395e"
            >
              Know Why?
            </a>
          </div>

          <div className="text-black text-sm font-normal font-['Inter'] leading-tight mt-6">
            Connect your social media
          </div>
          <div
            className={`grid gap-x-8 gap-y-3 mt-2 mb-10
              ${isMobile() ? 'grid-cols-1' : 'grid-cols-2'}`}
          >
            <ModalSelectPlatformContent />
          </div>

          <div className={styles.tips}>
            <p>
              {' '}
              • Connecting your socials allows us to verify you have ownership
              of your accounts.
            </p>
            <p>
              {' '}
              • Aha never accesses your socials or passwords — your info stays
              safe.
            </p>
          </div>
          <div className={styles.bottom}>
            <div className={styles.secure}>
              <img src="/c-lock.svg" alt="lock" className={styles.lockIcon} />
              <span>Your data is secure</span>
            </div>
          </div>
        </div>
      </div>
      {!isMobile() && (
        <div className={styles.bg}>
          <div className={styles.img}>
            <img src="/c-connect-bg.png" alt="bg" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectPage;
