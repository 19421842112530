import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {
  isMobile,
  getAuthorizeError,
  getUpdateTypeFromUrlSearch,
  getUpdateReasonFromUrlSearch,
  getShowModalAnalysisFromUrlSearch,
  getShowModalConfirmDealPriceFromUrlSearch,
  getDebugger,
  getAuthorizeErrorMessage
} from '@/biz/tool';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import ModalAnalysisBreadcrumb from './ModalAnalysisBreadcrumb';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { PlatformIconComponent } from '@/biz/platform';
import {
  LockIcon,
  SafeIcon,
  BrandValueIcon,
  SuccessIcon,
  AuthorizeErrorIcon
} from '@/components/common/icon';
import toast from 'react-hot-toast';
import { useOrderListStore } from '@/hooks/useOrderListStore';
import { ConnectBtn } from './ModalSelectPlatformContent';
import { OrderStatus } from '@/interface/order';

const ModalShowAnInterest: React.FC = () => {
  const { detailData } = useDetailDataStore();
  const {
    showModalAnalysis,
    setShowModalAnalysis,
    setShowModalMissOut,
    setShowModalSelectInvitedOrderByNoMatch,
    setShowModalConfirmDealPrice
  } = useWebModalStore();
  const { reQueryOrderListData } = useOrderListStore();

  useEffect(() => {
    if (
      window?.location?.pathname === '/clerk' ||
      window?.location?.pathname.includes('/auth/')
    ) {
      return;
    }
    // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
    const updateType = getUpdateTypeFromUrlSearch();
    const updateReason = getUpdateReasonFromUrlSearch();
    const AuthorizeError = getAuthorizeError();
    const AuthorizeErrorMessage = getAuthorizeErrorMessage();
    const urlShowModalAnalysis = getShowModalAnalysisFromUrlSearch();
    const urlShowModalConfirmDealPrice =
      getShowModalConfirmDealPriceFromUrlSearch();

    if (AuthorizeError === 'true') {
      if (updateType === 'cancel_order') {
        getOrderListAndShowInvited();
      } else {
        //
        setShowModalAnalysis(true);
        //
        if (
          updateReason === 'mismatch_audience' ||
          updateReason === 'content_check_failed'
        ) {
          //
        } else if (AuthorizeErrorMessage) {
          toast.custom(
            <div className="px-4 py-3 bg-[#ffebe5] text-[#d43710] rounded-lg flex justify-start items-center gap-2">
              <AuthorizeErrorIcon className="w-6 h-6" />
              {AuthorizeErrorMessage || updateReason}
            </div>,
            {
              duration: 3000
            }
          );
        }
      }

      // 清除 url 中的 AuthorizeError
      window.history.replaceState(null, '', window.location.pathname);
    }

    if (urlShowModalAnalysis === 'true') {
      setShowModalAnalysis(true);
      window.history.replaceState(null, '', window.location.pathname);
    } else if (urlShowModalConfirmDealPrice === 'true') {
      setShowModalConfirmDealPrice(true);
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, []);

  const getOrderListAndShowInvited = async () => {
    // 请求 invited 订单
    setShowModalSelectInvitedOrderByNoMatch(true);
    await reQueryOrderListData();
    // end 请求 invited 订单
  };

  const AnalysisContent = () => {
    return (
      <div
        className={`relative w-full flex flex-col items-start gap-6
          ${isMobile() ? 'h-[100vh]' : 'max-h-[70vh]'}
        `}
      >
        <ModalAnalysisBreadcrumb index={0} />
        <div
          className={`overflow-auto flex flex-col gap-6
          ${isMobile() ? 'h-[calc(100vh-210px)]  pb-10' : ''}`}
        >
          <div>
            <span className="text-[#1c2024] text-base font-normal font-['Inter'] leading-7">
              Connecting your social allows us to verify you have ownership of
              your account. Aha never accesses your socials or passwords — your
              info stays safe.
            </span>
            <a
              className={`text-[#0c67e6] text-base font-normal font-['Inter'] leading-7 hover:underline ml-1`}
              target="_blank"
              rel="noreferrer"
              href="https://chartreuse-wrinkle-697.notion.site/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68804a9ba3c6bed4546503"
            >
              Know Why?
            </a>
          </div>
          <div className="w-full h-[90px] px-6 py-[29px] bg-[#f7f8ff] rounded-lg justify-between items-center inline-flex">
            <div className="h-12 justify-start items-center gap-2 flex">
              <div className="justify-start items-center gap-3 flex relative">
                <img
                  // alt="avatar"
                  className="w-12 h-12 rounded-full"
                  src={detailData?.influencer?.profilePictureLink}
                />
                <div className="absolute right-0 bottom-0">
                  <PlatformIconComponent
                    platform={detailData?.order?.platform}
                    className="w-4 h-4"
                  />
                </div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                <div className="text-zinc-950 text-base font-semibold font-['Plus Jakarta Sans']">
                  {detailData?.influencer?.name}
                </div>
                <div className="self-stretch text-[#8b8d98] text-sm font-normal font-['Inter']">
                  {detailData?.influencer?.username
                    ? detailData.influencer.username.startsWith('@')
                      ? detailData.influencer.username
                      : `@${detailData.influencer.username}`
                    : ''}
                </div>
              </div>
            </div>
            {!detailData?.influencer?.isAuthorized ? (
              <ConnectBtn
                platform={{ platform: detailData?.order?.platform, id: null }}
                isConnected={false}
                urlShowModalAnalysis={true}
                uiStyle="blackConnect"
              />
            ) : (
              <div className="flex items-center gap-2">
                <SuccessIcon className="w-5 h-5" />
                <div className="text-[#287f2e] text-sm font-medium font-['Inter'] leading-snug">
                  Success
                </div>
              </div>
            )}
          </div>
          {/*  */}
          <div className="w-full flex items-center gap-4">
            <div className="flex-1 h-[1px] bg-[#eeeeee]"></div>
            <div className="w-auto flex items-center gap-1 text-[#60646c] text-sm font-medium font-['Inter'] leading-snug">
              <LockIcon className="w-4 h-4 text-[#60646c]" />
              Your data is secure
            </div>
            <div className="flex-1 h-[1px] bg-[#eeeeee]"></div>
          </div>

          {/*  */}
          <div className="justify-start items-start gap-3 inline-flex">
            <div className="grow shrink basis-0 self-stretch p-3 bg-gradient-to-b from-[#f9f2ea] to-[#f9f2ea] rounded-lg flex-col justify-start items-start gap-2 inline-flex">
              <SafeIcon className="w-4 h-4" />
              <div className="self-stretch text-[#6f4e45] text-sm font-normal font-['Inter'] leading-normal">
                Connecting your socials makes sure your deals can only accepted
                by yourself.
              </div>
            </div>
            <div className="grow shrink basis-0 self-stretch p-3 bg-gradient-to-b from-[#f9f2ea] to-[#f9f2ea] rounded-lg flex-col justify-start items-start gap-2 inline-flex">
              <BrandValueIcon className="w-4 h-4" />
              <div className="self-stretch text-[#6f4e45] text-sm font-normal font-['Inter'] leading-normal">
                You can also secure more brand deals tailored to your channel.
              </div>
            </div>
          </div>
        </div>
        {/* {isMobile() ? <div className="h-[100px] w-full mb-[200px]">2</div> : null} */}
        {/*  */}
        <div
          className={`bg-white w-full flex  gap-3 border-t border-solid border-[#e8e8ec] pt-4 mt-4
          ${isMobile() ? 'justify-center fixed bottom-0 left-0 pb-4' : 'justify-end'}
          `}
        >
          <Button
            className="mui-btn-gray !w-[160px] !h-10"
            onClick={() => {
              setShowModalAnalysis(false);
              setShowModalMissOut(true);
            }}
          >
            Cancel
          </Button>
          <Button
            className="mui-btn-primary !w-[160px] !h-10"
            disabled={
              getDebugger() === 'true'
                ? false
                : !detailData?.influencer?.isAuthorized
            }
            onClick={() => {
              setShowModalAnalysis(false);
              setShowModalConfirmDealPrice(true);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    );
  };

  if (!detailData?.order?.status.includes(OrderStatus.Invited)) {
    return null;
  }

  return (
    <>
      {isMobile() ? (
        <BottomDrawer
          title="Connect your social"
          visible={showModalAnalysis}
          handleClose={() => setShowModalAnalysis(false)}
          isShowFooter={false}
          rootClassName="!z-[9999]"
        >
          <AnalysisContent />
        </BottomDrawer>
      ) : (
        <WebModal
          width={810}
          title="Connect your social"
          visible={showModalAnalysis}
          handleClose={() => setShowModalAnalysis(false)}
          isShowFooter={false}
        >
          <AnalysisContent />
        </WebModal>
      )}
    </>
  );
};

export default ModalShowAnInterest;
