import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { OrderStatus } from '@/interface/order';
import { isMobile } from '@/biz/tool';
import BottomDrawer from './BottomDrawer';
import WebModal from './Modal';
import { useWebModalStore } from '@/hooks/useWebModalStore';
const ModalMissOut: React.FC<{}> = () => {
  const { showModalMissOut, setShowModalMissOut, setShowModalAnalysis } =
    useWebModalStore();

  const ModalContent = () => {
    return (
      <div
        className={`w-full flex flex-col items-center gap-6
          ${isMobile() ? 'h-[calc(100vh)]' : 'max-h-[70vh]'}
      `}
      >
        <div
          className={`relative w-full flex flex-col items-center gap-6 
        `}
        >
          <div
            className={`overflow-auto flex flex-col gap-6 items-center
          ${isMobile() ? ' pb-20 pt-10' : ''}`}
          >
            <img
              src="/modal-not-interest.png"
              alt="modal-not-interest"
              className="w-[140px] h-[140px]"
            />
            <div
              className={`text-center text-[#1c2024] text-[28px] font-semibold font-['Geist'] leading-[50px]
            ${isMobile() ? 'px-0' : 'px-16'}`}
            >
              Are You Sure You Want to Miss Out on This Brand Collaboration?
            </div>
            <div className=" text-center text-[#60646c] text-base font-normal font-['Inter'] leading-7">
              Joining early increases your chances of securing a valuable
              partnership. Don’t miss the opportunity to establish and grow with
              top brands.
            </div>
          </div>
        </div>
        {/*  */}
        <div
          className={`bg-white w-full flex justify-end items-center gap-4 border-t border-solid border-[#e8e8ec] pt-4 mt-4
            ${isMobile() ? 'justify-center fixed bottom-0 left-0 pb-4' : ''}
          `}
        >
          <Button
            onClick={() => setShowModalMissOut(false)}
            className={`mui-btn-gray !h-10 !w-auto
                ${isMobile() ? '!px-6' : '!px-10'}`}
          >
            Leave
          </Button>
          <Button
            onClick={() => {
              setShowModalAnalysis(true);
              setShowModalMissOut(false);
            }}
            className={`mui-btn-primary !h-10 !w-auto !px-5`}
          >
            Continue connecting
          </Button>
        </div>
      </div>
    );
  };
  return (
    <>
      {isMobile() ? (
        <BottomDrawer
          visible={showModalMissOut}
          handleClose={() => setShowModalAnalysis(false)}
          isShowFooter={false}
          isShowHeader={false}
          rootClassName="!z-[9999]"
        >
          <ModalContent />
        </BottomDrawer>
      ) : (
        <WebModal
          width={810}
          visible={showModalMissOut}
          handleClose={() => setShowModalAnalysis(false)}
          isShowFooter={false}
          isShowHeader={false}
        >
          <ModalContent />
        </WebModal>
      )}
    </>
  );
};

export default ModalMissOut;
