import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper/modules';
import { isMobile } from '@/biz/tool';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { Swiper as SwiperType } from 'swiper';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { platform } from 'os';
import { AdsPlatform } from '@/interface/order';

const ModalFlowGuide: React.FC = () => {
  const { detailData } = useDetailDataStore();
  const { showFlowGuideModal, setShowFlowGuideModal } = useWebModalStore();
  const swiperRef = useRef<SwiperType>();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  return null;

  return (
    <Modal
      open={showFlowGuideModal}
      onClose={() => setShowFlowGuideModal(false)}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      disablePortal
    >
      <div
        className={`bg-white relative  left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]  
        ${isMobile() ? 'w-[342px] py-8 rounded-2xl' : 'w-[598px] py-6 rounded-lg'}`}
      >
        <Swiper
          id="aha-swiper"
          modules={[Navigation, Pagination, A11y, Autoplay]}
          slidesPerView={1}
          loop
          // autoplay={{
          //   delay: 3000,
          //   disableOnInteraction: false
          // }}
          pagination={{
            clickable: true,
            bulletClass: 'swiper-pagination-bullet',
            bulletActiveClass:
              'swiper-pagination-bullet-active !bg-blue-600 !opacity-100'
          }}
          className={`w-full [&_.swiper-pagination]:!bottom-auto 
            ${isMobile() ? ' [&_.swiper-pagination]:!top-[calc(228px+0px)]' : ' [&_.swiper-pagination]:!top-[calc(350px+0px)]'}
            `}
          onSwiper={(swiper: any) => {
            swiperRef.current = swiper;
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          onSlideChange={(swiper: any) => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
        >
          {getSwiperData(detailData?.order?.platform).map((item, index) => (
            <SwiperSlide
              key={index}
              className="flex flex-col items-center px-6"
            >
              <div
                className={`
                  ${isMobile() ? 'mb-[26px] w-[294px] h-[228px]' : 'mb-[42px] w-[544px] h-[343px]'} rounded-xl overflow-hidden`}
              >
                <img
                  src={item.avatar}
                  alt={item.title}
                  className="h-full w-full object-cover"
                />
              </div>
              <h2
                className={`text-xl text-[#1c2024] font-semibold text-center
                ${isMobile() ? 'mb-2' : 'mb-4'}`}
              >
                {item.title}
              </h2>
              <p
                className={`text-sm text-gray-500 text-center 
                ${isMobile() ? 'mb-[24px]' : 'mb-[26px]'}`}
              >
                {item.desc}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="px-6 flex gap-4">
          {!isBeginning && (
            <Button
              variant="contained"
              fullWidth
              className="mui-btn-default !w-[120px] !h-10 !text-[#5b3de9] !border-[#5b3de9] !border-solid"
              onClick={() => {
                swiperRef.current?.slidePrev();
              }}
            >
              Back
            </Button>
          )}

          <Button
            variant="contained"
            fullWidth
            className="mui-btn-primary !h-10"
            onClick={() => {
              if (isEnd) {
                setShowFlowGuideModal(false);
                window.location.reload();
              } else {
                swiperRef.current?.slideNext();
              }
            }}
          >
            {isEnd ? 'Got it' : 'Continue'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFlowGuide;

interface SwiperItemProps {
  avatar: string;
  title: string;
  desc: string;
}

const TiktokSwiperData: SwiperItemProps[] = [
  {
    avatar: isMobile()
      ? '/first-order-modal/banner02-mobile.png'
      : '/first-order-modal/banner02.png',
    title: 'Step 1: Submit post draft',
    desc: 'After the application is approved, please click “Submit draft” to paste your draft link.'
  },
  {
    avatar: isMobile()
      ? '/first-order-modal/banner03-mobile.png'
      : '/first-order-modal/banner03.png',
    title: 'Step 2: Publish and submit link',
    desc: 'Revise your post draft based on feedback and submit your final post link to seal the deal!'
  }
];

const InstagramSwiperData = TiktokSwiperData;
const TwitterSwiperData = TiktokSwiperData;
const LinkedInSwiperData = TiktokSwiperData;

const YoutubeSwiperData: SwiperItemProps[] = [
  {
    avatar: isMobile()
      ? '/first-order-modal/banner01-mobile.png'
      : '/first-order-modal/banner01.png',
    title: 'Step1: Submit script',
    desc: 'Once the brand confirms the deal on their end, you can begin by submitting your script draft.'
  },
  {
    avatar: isMobile()
      ? '/first-order-modal/banner02-mobile.png'
      : '/first-order-modal/banner02.png',
    title: 'Step2: Submit content',
    desc: 'When your script draft is green-lit, you can start creating your video content.'
  },
  {
    avatar: isMobile()
      ? '/first-order-modal/banner03-mobile.png'
      : '/first-order-modal/banner03.png',
    title: 'Step3: Submit final link',
    desc: 'Revise your video draft based on feedback and submit your final post link to seal the deal!'
  }
];

const getSwiperData = (platform: string): SwiperItemProps[] => {
  if (platform === AdsPlatform.Youtube) {
    return YoutubeSwiperData;
  } else if (platform === AdsPlatform.TikTok) {
    return TiktokSwiperData;
  } else if (platform === AdsPlatform.Instagram) {
    return InstagramSwiperData;
  } else if (platform === AdsPlatform.Twitter) {
    return TwitterSwiperData;
  } else if (platform === AdsPlatform.LinkedIn) {
    return LinkedInSwiperData;
  }
  return YoutubeSwiperData;
};
