import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { isMobile } from '@/biz/tool';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import ModalAnalysisBreadcrumb from './ModalAnalysisBreadcrumb';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { WarnIcon } from '@/components/common/icon';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { NumericFormat } from 'react-number-format';
import PriceFieldsComponent from './PriceFieldsComponent';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import { getUpdateReasonFromUrlSearch } from '@/biz/tool';
import { OrderStatus } from '@/interface/order';
interface NumberFormatUSDProps {
  inputRef: (instance: any) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatUSD(props: NumberFormatUSDProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      // suffix=" USD"
    />
  );
}

const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      border: '1px solid #E8E8EC',
      backgroundColor: '#ffffff',
      marginTop: '8px',
      marginBottom: '8px',
      '&:before': {
        borderBottom: '1px solid #E8E8EC' // 默认状态的底线颜色
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: '2px solid #5B3DE9' // hover 状态的底线颜色
      },
      '&.Mui-focused:after': {
        borderBottom: '2px solid #5B3DE9' // 输入态（focused）的底线颜色
      },
      '&.Mui-disabled': {
        '&:before': {
          borderBottomStyle: 'solid'
        }
      },
      borderRadius: '6px',
      '& input': {
        padding: '8px',
        caretColor: '#295eff', // 设置输入光标为紫色'
        '&::placeholder': {
          color: '#8B8D98',
          opacity: 1,
          fontSize: '14px',
          fontWeight: 300,
          fontFamily: 'Inter'
        }
      }
    }
  }
})(TextField);

const ModalShowAnInterest: React.FC = () => {
  const { showModalConfirmDealPrice, setShowModalConfirmDealPrice } =
    useWebModalStore();
  const { detailData } = useDetailDataStore();

  if (!detailData?.order?.status.includes(OrderStatus.Invited)) {
    return null;
  }

  return (
    <>
      {isMobile() ? (
        <BottomDrawer
          title="Confirm deal price"
          visible={showModalConfirmDealPrice}
          handleClose={() => setShowModalConfirmDealPrice(false)}
          isShowFooter={false}
          rootClassName="!z-[9999]"
        >
          <AnalysisContent />
        </BottomDrawer>
      ) : (
        <WebModal
          width={810}
          title="Confirm deal price"
          visible={showModalConfirmDealPrice}
          handleClose={() => setShowModalConfirmDealPrice(false)}
          isShowFooter={false}
        >
          <AnalysisContent />
        </WebModal>
      )}
    </>
  );
};

const AnalysisContent: React.FC = () => {
  const { detailData } = useDetailDataStore();
  const {
    showModalConfirmDealPrice,
    setShowModalConfirmDealPrice,
    setShowModalMissOut,
    setShowModalSubmitInterest
  } = useWebModalStore();
  const {
    inputPrice,
    setInputPrice,
    clickedSubmitANewPrice,
    setClickedSubmitANewPrice
  } = useMultiplePricesStore();

  const needUpdate = detailData?.order?.validateResult?.need_update;
  const updateReason = detailData?.order?.validateResult?.update_reason;

  return (
    <div
      className={`w-full flex flex-col items-start gap-6
      ${isMobile() ? 'h-[calc(100vh)]' : ''}
    `}
    >
      <ModalAnalysisBreadcrumb index={1} />
      <div
        className={`overflow-auto flex flex-col gap-6
          ${isMobile() ? 'h-[calc(100vh-210px)]  pb-10' : ''}`}
      >
        {needUpdate && (
          <div className="w-full p-2 bg-[#ffeddc] rounded-lg justify-start inline-flex items-center gap-2">
            <WarnIcon className="w-6 " />
            <div className="grow shrink basis-0 text-[#1c2024] text-xs font-medium font-['Inter'] leading-[18px]">
              {updateReason?.includes('mismatch_audience')
                ? 'Based on how well your audience aligns with our brand requirements, '
                : null}
              {updateReason?.includes('content_check_failed')
                ? 'Based on your actual organic view data, '
                : null}
              we have adjusted the pricing for your deal accordingly. Please
              review the updated price details below.
            </div>
          </div>
        )}
        {/*  */}
        <div className="text-[#1c2024] text-base font-semibold font-['Plus Jakarta Sans'] leading-snug">
          Select content type{' '}
        </div>
        {/*  */}
        <PriceFieldsComponent
          direction={isMobile() ? 'flex flex-col' : 'flex flex-row'}
          hasRadioButton={true}
        />

        {/*  */}
        <div className="px-3 py-2 bg-neutral-100 rounded-lg flex flex-col items-start gap-2">
          <span className="text-[#203c20] text-xs font-normal font-['Inter'] leading-[18px]">
            💡 Creators who follow the platform's suggested pricing guidelines
            have a higher chance of securing brand deals. Not happy with the
            suggested pricing?{' '}
            <span
              className={`text-[#0c67e6] text-xs font-normal font-['Inter'] leading-7 hover:underline ml-1
              ${clickedSubmitANewPrice ? 'cursor-not-allowed' : 'cursor-pointer'}
              `}
              onClick={() => setClickedSubmitANewPrice(true)}
            >
              Submit a new price
            </span>
          </span>

          <div
            className="w-full flex-col items-start gap-2"
            style={{ display: clickedSubmitANewPrice ? 'flex' : 'none' }}
          >
            <div className="text-[#1c2024] text-sm font-semibold font-['Plus Jakarta Sans'] leading-snug">
              Your new price proposal
            </div>

            <StyledTextField
              variant="outlined"
              fullWidth
              value={inputPrice}
              onChange={(event) => {
                setInputPrice(event.target.value);
              }}
              placeholder="Enter your expected price."
              name="price"
              InputProps={{
                startAdornment: (
                  <div className="text-zinc-500 text-sm font-normal font-['Inter'] mr-1">
                    $
                  </div>
                ),
                endAdornment: (
                  <div className="text-[#1c2024] text-sm font-normal font-['Inter']">
                    USD
                  </div>
                ),
                inputComponent: NumberFormatUSD as any,
                inputProps: {
                  decimalScale: 0,
                  allowNegative: false,
                  isAllowed: (values: any) => {
                    const { value } = values;
                    return (
                      (value === '' || Number(value) > 0) && value.length <= 8
                    );
                  }
                },
                style: {
                  width: '100%',
                  fontSize: '1rem',
                  fontWeight: 600,
                  fontFamily: 'Geist'
                }
              }}
            />

            <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-[18px]">
              Please note that higher pricing may require additional time from
              the brand to confirm the collaboration.
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div
        className={`bg-white w-full flex justify-end items-center gap-4 border-t border-solid border-[#e8e8ec] pt-4 mt-4
            ${isMobile() ? 'justify-center fixed bottom-0 left-0 pb-4' : ''}
          `}
      >
        <Button
          className="mui-btn-gray !w-[160px] !h-10"
          onClick={() => {
            setShowModalConfirmDealPrice(false);
            setShowModalMissOut(true);
          }}
        >
          Cancel
        </Button>
        <Button
          className="mui-btn-primary !w-[160px] !h-10"
          disabled={clickedSubmitANewPrice && !inputPrice}
          onClick={() => {
            setShowModalConfirmDealPrice(false);
            setShowModalSubmitInterest(true);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ModalShowAnInterest;
