import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '@/biz/tool';
import WebModal from '@/components/web/components/Modal';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import { useOrderListStore } from '@/hooks/useOrderListStore';
import ProductLogo from '@/components/web/components/ProductLogo';
import Button from '@material-ui/core/Button';
import { normalizePriceUsd } from '@/utils/normalizePriceUsd';
import BottomDrawer from '@/components/web/components/BottomDrawer';
import { CoffeeIcon, RightViewIcon, BadgeIcon } from '@/components/common/icon';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import CircularProgress from '@material-ui/core/CircularProgress';

const ModalSelectInvitedOrderByNoMatch: React.FC = () => {
  const { detailData } = useDetailDataStore();
  const {
    showModalSelectInvitedOrderByNoMatch,
    setShowModalSelectInvitedOrderByNoMatch,
    showModalSelectInvitedOrderByNoMatchType: by
  } = useWebModalStore();
  const { orderListObject, isLoadingData } = useOrderListStore();
  const { invites = [] } = orderListObject || {};
  const [noneOrderList, setNoneOrderList] = useState(false);

  useEffect(() => {
    if (!isLoadingData) {
      setNoneOrderList(!Boolean(invites?.length));
    }
  }, [isLoadingData]);

  const ModalContent = () => {
    return (
      <>
        <div
          className={`w-full flex-1 flex-col justify-start items-center inline-flex  overflow-y-auto
            ${isMobile() ? 'h-[100vh]' : 'max-h-[70vh]'}
            ${noneOrderList ? 'mt-[200px] mb-[200px]' : ''}
            `}
        >
          <div
            className={`flex flex-col px-4
          ${isMobile() ? 'gap-4' : ''}
          `}
          >
            {by === 'success' ? (
              <>
                <div
                  className={`flex items-center justify-center text-center text-[#203c20] text-xl font-semibold font-['Geist']
                  ${isMobile() ? 'flex-col' : 'flex-row'}
                `}
                >
                  <>
                    <BadgeIcon className="w-[28px] h-[28px]" />
                    Your interest has been successfully submitted.
                  </>
                </div>
                <div className="text-center text-[#203c20]/60 text-base font-normal font-['Inter'] leading-normal">
                  Thank you for expressing interest in the{' '}
                  {detailData?.product?.name} deal! We will send you an email
                  with the next steps within 2-3 business days.
                </div>
              </>
            ) : (
              <>
                <div
                  className={`flex items-center justify-center text-center text-[#5c281d] text-xl font-semibold font-['Geist']
                  ${isMobile() ? 'flex-col' : 'flex-row'}
                `}
                >
                  <>
                    <CoffeeIcon className="w-[28px] h-[28px]" />
                    Your Audience Regions Do Not Match the Brand's Requirements
                  </>
                </div>
                <div className="text-center text-[#5c281d]/60 text-base font-normal font-['Inter'] leading-normal">
                  We’re sorry, but your audience regions do not align with the
                  brand’s current requirements. However, we have identified
                  other opportunities that may be a better fit for you.
                </div>
              </>
            )}
          </div>

          {/*  */}
          {isLoadingData ? (
            <div className="w-full h-full min-h-[200px] flex justify-center items-center">
              <CircularProgress
                color="inherit"
                className="!w-10 !h-10"
                disableShrink
              />
            </div>
          ) : null}
          {/*  */}
          <div
            style={{
              display: !noneOrderList && invites.length ? 'block' : 'none'
            }}
            className={`w-full  grid gap-x-8 gap-y-3 mt-6 mb-10 bg-[#ffffff] rounded-l-3xl rounded-r-3xl px-6 pt-6
              ${isMobile() ? 'grid-cols-1 mx-[-10px]' : 'grid-cols-1'}`}
          >
            <div className="text-[#1c2024] text-base font-semibold font-['Geist'] leading-7">
              More invites
            </div>
            {invites?.map((item: any) => (
              <div
                className={`w-full min-h-24 p-4 justify-between items-center inline-flex border-b border-solid border-[#e8e8ec]
                  ${isMobile() ? 'w-full' : 'w-[560px]'}`}
              >
                <div className="justify-start items-center gap-4 flex">
                  <ProductLogo src={item?.campaign?.product?.logo} size={64} />
                  <div className="flex-col justify-start items-start gap-1 inline-flex flex-1">
                    <div className="self-stretch justify-start items-center gap-1 inline-flex">
                      <div className="text-[#1c2024] text-base font-semibold font-['Plus Jakarta Sans'] leading-snug">
                        {normalizePriceUsd(item?.priceFields?.[0]?.price)}
                      </div>
                    </div>
                    <div className="self-stretch justify-start items-center gap-2 inline-flex">
                      <div className="justify-start items-start flex flex-wrap">
                        <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
                          {item?.campaign?.product?.name}
                        </div>
                        <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight">
                          ｜
                        </div>
                        <a
                          className="text-[#60646c] text-xs font-normal font-['Inter'] leading-tight break-all hover:text-[#0c67ef]"
                          href={item?.campaign?.product?.website}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item?.campaign?.product?.website}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  variant="contained"
                  className={`mui-btn-gray !w-[77px] !max-w-[77px] !h-[32px] !px-0 flex-shrink-0`}
                  onClick={() => {
                    // 关闭弹窗
                    setShowModalSelectInvitedOrderByNoMatch(false);
                    window.location.href = `/campaign/invites/detail/${item?.id}`;
                  }}
                >
                  <RightViewIcon />
                </Button>
              </div>
            ))}
          </div>
        </div>
        {/*  */}
        <div
          style={{
            display: !noneOrderList ? 'flex' : 'none'
          }}
          className={`w-full flex  gap-3 border-t border-solid border-[#e8e8ec] pt-4 mt-4
          ${isMobile() ? 'justify-center' : 'justify-end'}
          `}
        >
          <Button
            className="mui-btn-gray !w-[160px] !h-10"
            onClick={() => {
              setShowModalSelectInvitedOrderByNoMatch(false);
            }}
          >
            Not now
          </Button>
          <Button
            className="mui-btn-primary !w-[160px] !h-10"
            onClick={() => {
              setShowModalSelectInvitedOrderByNoMatch(false);
              window.location.href = `/campaign/invites`;
            }}
          >
            View all
          </Button>
        </div>
        {/*  */}
        <div
          style={{
            display: noneOrderList ? 'flex' : 'none'
          }}
          className={`w-full flex gap-3 border-t border-solid border-[#e8e8ec] pt-4 mt-4
          ${isMobile() ? 'justify-center' : 'justify-end'}
          `}
        >
          <Button
            className="mui-btn-primary !w-[160px] !h-10"
            onClick={() => {
              setShowModalSelectInvitedOrderByNoMatch(false);
            }}
          >
            Back to deal details
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {isMobile() ? (
        <BottomDrawer
          isShowHeader={false}
          visible={showModalSelectInvitedOrderByNoMatch}
          isShowFooter={false}
          width={isMobile() ? 402 : 624}
          handleClose={() => {
            setShowModalSelectInvitedOrderByNoMatch(false);
          }}
          // 页面级别 最上层
          rootClassName="!z-[9999]"
          className={`${
            by === 'success'
              ? 'bg-gradient-to-b from-[#d9f1d9] via-[#f2faff] to-white'
              : 'bg-gradient-to-b from-[#ffdad0] via-[#fff6ee] to-white'
          }`}
          keepMounted={true}
        >
          <ModalContent />
        </BottomDrawer>
      ) : (
        <WebModal
          isShowHeader={false}
          visible={showModalSelectInvitedOrderByNoMatch}
          isShowFooter={false}
          width={isMobile() ? 402 : 810}
          handleClose={() => {
            setShowModalSelectInvitedOrderByNoMatch(false);
          }}
          className={`${
            by === 'success'
              ? 'bg-gradient-to-b from-[#d9f1d9] via-[#f2faff] to-white'
              : 'bg-gradient-to-b from-[#ffdad0] via-[#fff6ee] to-white'
          }`}
          keepMounted={true}
        >
          <ModalContent />
        </WebModal>
      )}
    </>
  );
};

export default ModalSelectInvitedOrderByNoMatch;
