import React, { useEffect, useState } from 'react';
import { normalizePrice } from '@/utils/normalizePrice';
import { WarnOutlineIcon } from '@/components/common/icon';
import { getPlatformName, getPlatformVideoContentType } from '@/biz/platform';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { OrderStatus } from '@/interface/order';
import Radio from '@/components/Radio/index';

const PriceFieldsComponent = ({
  direction = 'flex flex-col',
  hasRadioButton = false
}: {
  direction?: 'flex flex-row' | 'flex flex-col';
  hasRadioButton?: boolean;
}) => {
  const { detailData } = useDetailDataStore();
  const { gbPricingSchemaFlag } = useMultiplePricesStore();

  const { order } = detailData;
  const priceFields = order?.priceFields;
  const interestedPriceFields = order?.interestedPriceFields;
  // // 有两个价格了 且 没有提交感兴趣价格
  // const showTwoPrice = Boolean(
  //   interestedPriceFields?.length === 0 && priceFields?.length > 1
  // );

  // // 专用视频价格
  // const DedicatedVideoPrice =
  //   order?.priceFields?.find(
  //     (el: any) => el.contentType === 'Dedicated video'
  //   ) || order?.priceFields[0]?.contentType
  //     ? {
  //         contentType: order?.priceFields[0]?.contentType,
  //         price: order?.priceFields[0]?.price
  //       }
  //     : { contentType: 'Dedicated video', price: order?.price };
  // // 集成视频价格
  // const IntegratedVideoPrice =
  //   order?.priceFields?.find(
  //     (el: any) => el.contentType === 'Integrated video'
  //   ) || {};

  if (!gbPricingSchemaFlag) {
    // 暂未拿到 ab 测试结果
    return (
      <div className="w-full flex justify-center items-center gap-8 ">
        loading price...
      </div>
    );
  }

  // no.1 老订单
  // old 单一内容类型定价
  if (!priceFields?.length) {
    return (
      <div className="w-full flex flex-col justify-center items-center gap-8 ">
        <PriceFieldItem
          priceData={{
            price: order?.price,
            contentType: getPlatformVideoContentType({
              platform: order?.platform
            })
          }}
          alwaysShowPrice
          hasRadioButton={hasRadioButton}
        />
        {order?.status.includes(OrderStatus.Invited) && (
          <p>
            The order is abnormal, please contact{' '}
            <a
              className="text-[#0c67e6] cursor-pointer"
              href="mailto:support@creator.ahaglobal.io"
            >
              support@creator.ahaglobal.io
            </a>
            .
          </p>
        )}
      </div>
    );
  }

  // no.2 新订单，已提交价格
  if (interestedPriceFields?.length > 0) {
    return (
      <div className="w-full justify-center items-center gap-4 ">
        {interestedPriceFields?.map((el: any, index: number) => {
          return (
            <React.Fragment key={`${el.contentType}-${index}`}>
              <PriceFieldItem
                priceData={el}
                alwaysShowPrice
                hasRadioButton={hasRadioButton}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  // no.3 新订单，未提交价格，gbPricingSchemaFlag是 not_display_price_can_change
  if (priceFields?.length && !interestedPriceFields?.length) {
    // 加入前 不展示定价
    if (gbPricingSchemaFlag === 'not_display_price_can_change') {
      return (
        <div className="w-full flex justify-center items-center gap-8 ">
          <span>Open budget</span>
        </div>
      );
    }
  }

  // no.4 新订单，未提交价格
  return (
    <>
      {/*  */}
      <div
        className={`w-full justify-center items-center gap-4
        ${direction}
        `}
      >
        {priceFields?.map((el: any, index: number) => {
          return (
            <React.Fragment key={`${el.contentType}-${index}`}>
              <PriceFieldItem
                priceData={el}
                alwaysShowPrice
                hasRadioButton={hasRadioButton}
              />
            </React.Fragment>
          );
        })}
      </div>
      {/*  */}
      {/* {priceFields?.length &&
      !interestedPriceFields?.length &&
      gbPricingSchemaFlag === 'display_price_can_change' ? (
        <div className="px-4 py-2 bg-[#eaf3ff] rounded justify-start items-start gap-2 inline-flex text-xs w-full">
          <WarnOutlineIcon className="w-4 h-4 flex-shrink-0" />
          <span>Fixed price. Open to new price proposal. </span>
        </div>
      ) : null} */}
    </>
  );
};

const PriceFieldItem = ({
  priceData,
  alwaysShowPrice,
  hasRadioButton = false
}: {
  priceData: any;
  alwaysShowPrice?: boolean;
  hasRadioButton?: boolean;
}) => {
  const { detailData } = useDetailDataStore();
  const { order } = detailData;
  const interestedPriceFields = order?.interestedPriceFields;

  const { selectedPriceFields, setSelectedPriceFields, gbPricingSchemaFlag } =
    useMultiplePricesStore();
  const { contentType, price, originPrice } = priceData;
  let showPrice = price;
  if (order?.status.includes(OrderStatus.Invited)) {
    // Invited 的时候 可以更改 price字段。
    // 且 只有 Invited 的时候 优先展示 originPrice 字段
    showPrice = originPrice;
  }

  return (
    <div
      className={`gap-1 flex flex-col justify-center items-center border border-solid rounded-lg px-4 py-3 w-full
      ${
        selectedPriceFields?.find((el) => el?.contentType === contentType) ||
        interestedPriceFields?.find(
          (el: any) => el?.contentType === contentType
        )
          ? 'border-[#5a31f0]'
          : 'border-[#e8e8ec]'
      }
          ${
            interestedPriceFields?.length > 0 ||
            !order?.status.includes(OrderStatus.Invited)
              ? 'cursor-default'
              : 'cursor-pointer'
          }
      `}
      onClick={() => {
        if (
          interestedPriceFields?.length > 0 ||
          !order?.status.includes(OrderStatus.Invited)
        ) {
          return;
        }
        setSelectedPriceFields([priceData]);
      }}
    >
      <div className="flex justify-between w-full">
        <div
          className={`flex justify-between w-full
        ${hasRadioButton ? 'flex-col items-start gap-2' : 'flex-row items-center'}
        `}
        >
          <div
            className={`text-[#1c2024] text-sm font-semibold font-['Plus Jakarta Sans'] leading-snug
            ${hasRadioButton ? 'text-[#8B8D98]' : ''}
            `}
          >
            {getPlatformVideoContentType({
              platform: order?.platform,
              contentType: contentType
            })}
          </div>
          {/* 下面两个显示价格 */}
          {gbPricingSchemaFlag === 'display_price_cannot_change' ||
          gbPricingSchemaFlag === 'display_price_can_change' ||
          alwaysShowPrice ? (
            <div className="text-[#1c2024] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
              {!isNaN(showPrice) ? normalizePrice(showPrice) : ''}
            </div>
          ) : null}
        </div>
        {hasRadioButton && (
          <Radio
            checked={Boolean(
              selectedPriceFields?.find((el) => el?.contentType === contentType)
            )}
          />
        )}
      </div>
      <div className="text-[#60646c] text-xs font-normal font-['Inter'] leading-[18px]">
        {getPlatformVideoContentType({
          platform: order?.platform,
          contentType: contentType
        })?.includes('Dedicated video')
          ? 'A standalone YouTube video created specifically to focus entirely on promoting the brand'
          : ''}
        {getPlatformVideoContentType({
          platform: order?.platform,
          contentType: contentType
        })?.includes('Integrated video')
          ? 'A YouTube video that seamlessly incorporates brand promotion within your regular content'
          : ''}
      </div>
    </div>
  );
};

export default PriceFieldsComponent;
