import React, { useEffect } from 'react';
import styles from './error.module.less';
import { getDetailPageUrl, getHomeUrl } from '@/biz/tool';
import Button from '@material-ui/core/Button';
import { Tracker } from '@/utils';
import { useLocation, useParams } from 'react-router-dom';
import { isMobile, getOrderId } from '@/biz/tool';
import { maskEmail } from '@/biz/tool';
import { getOrderPlatform } from '@/biz/order';

const ErrorPage: React.FC = () => {
  const { platform } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type');
  const message = query.get('message');
  const onboarding = query.get('onboarding');
  const orderId = getOrderId();

  let payload = query.get('payload') as any;
  if (payload) {
    payload = JSON.parse(payload);
  }

  useEffect(() => {}, []);

  let contentComponent = null;
  let buttonComponent = (
    <div className="mt-10 w-[300px]">
      <Button
        variant="contained"
        className="mui-btn-primary"
        onClick={() => {
          if (orderId) {
            Tracker.click('Return to detail');
            window.location.href = getDetailPageUrl({
              orderId,
              urlShowModalAnalysis: true
            });
          } else if (onboarding === 'true') {
            Tracker.click('Return to connect');
            window.location.href = '/connect';
          } else {
            Tracker.click('Return to connect');
            window.location.href = '/';
          }
        }}
      >
        {orderId
          ? 'Return to detail'
          : onboarding === 'true'
            ? 'Return to connect'
            : 'Return to home'}
      </Button>
    </div>
  );

  switch (type) {
    case 'cancelGoogle':
    case 'EXIT_FROM_CONNECTION_FAILED':
      // 主动点击取消按钮
      contentComponent = (
        <>
          <div className={styles.title}>
            {`Couldn't connect to ${platform}`}
          </div>
          <div className={styles.detail}>
            You have <strong>canceled the authorization</strong> of {platform}{' '}
            channel information, or you have{' '}
            <strong>not checked the required permissions</strong>. Please
            reconnect.
          </div>
        </>
      );
      break;
    case 'TOKEN_EXPIRED':
      contentComponent = (
        <>
          <div className={styles.title}>
            {`Couldn't connect to ${platform}`}
          </div>
          <div className={styles.detail}>token 过期或者不匹配</div>
        </>
      );
      break;
    case 'matchError':
      contentComponent = (
        <>
          <div className={styles.title}>Matching failed, please try again</div>
        </>
      );
      break;
    case 'FAILED':
      contentComponent = (
        <>
          <div className={styles.desc}>
            <p>
              {payload?.type === 'emptyChannel'
                ? `We were unable to find any ${platform} accounts under your email. Please try another.`
                : ''}
            </p>
            <p>
              {payload?.type === 'channelConnectedByOthers'
                ? `Your ${platform} account(s) are already associated with ${payload?.emails.map((email: string) => maskEmail(email)).join(', ')}. Try logging in with that email instead.`
                : ''}
            </p>
            {!payload?.type && (
              <p className="">
                Sorry, you failed to connect to {getOrderPlatform({ platform })}
                . Please try again.
              </p>
            )}
          </div>
        </>
      );
      break;
    case 'notMatchOrderAndEmail':
      contentComponent = (
        <>
          <div className={styles.title}>
            {`Please login with the same account`}
          </div>
          <div className={styles.desc}>
            <p>
              {`This order has already been bound to ${payload?.emails.map((email: string) => maskEmail(email)).join(', ')}. Try logging in with that email instead.`}
            </p>
          </div>
        </>
      );
      buttonComponent = (
        <div className="mt-10 w-[300px]">
          <Button
            variant="contained"
            className="mui-btn-primary"
            onClick={() => {
              window.location.replace(
                getDetailPageUrl({ orderId: payload?.orderId })
              );
            }}
          >
            Return to detail
          </Button>
        </div>
      );
      break;
    default:
      contentComponent = (
        <>
          <div className={styles.desc}>
            Sorry, you failed to connect to {getOrderPlatform({ platform })}.
            Please try again.
          </div>
        </>
      );
  }

  return (
    <div
      className={`${styles.container} ${isMobile() ? styles.mobileContainer : ''}`}
    >
      <div className={'flex flex-col items-center mt-10 max-w-md mx-auto'}>
        <img src={'/c-login-error.svg'} className={styles.linkedImg} />
        {contentComponent}
        {message && <div className="text-red-500">{message}</div>}
        {buttonComponent}
      </div>
    </div>
  );
};

export default ErrorPage;
