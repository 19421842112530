import React from 'react';
import { isMobile } from '@/biz/tool';
import styles from './ContentRequirements.module.less';
import { ReactComponent as Chevron } from '@/assets/icons/chevron-right.svg';
import {
  DedicatedVideoContents,
  IntegratedVideoContents,
  tTRequiredContents,
  InstagramRequiredContents,
  TwitterRequiredContents,
  LinkedInRequiredContents
} from './config';
import { PlatformItems } from '@/interface/common';
import ProductMustDo from './ProductMustDo';

const Card: React.FC<{
  item: { title: string; content: string[]; img: string };
}> = ({ item }) => {
  return (
    <div className={styles.items}>
      {item?.title && <div className={styles.itemsTitle}>{item?.title}</div>}
      <ul className={styles.itemsUl}>
        {item?.content?.map((c: any, cIndex: number) =>
          c?.title ? (
            <li>
              <span dangerouslySetInnerHTML={{ __html: c?.title }}></span>
              <ul>
                {c?.children?.map((child: string, i: number) => (
                  <li dangerouslySetInnerHTML={{ __html: child }}></li>
                ))}
              </ul>
            </li>
          ) : (
            <li dangerouslySetInnerHTML={{ __html: c }}></li>
          )
        )}
      </ul>
      <div className={styles.img}>
        <img src={item?.img} alt="" />
      </div>
    </div>
  );
};

const ContentRequirements: React.FC<{ platform: PlatformItems; data: any }> = ({
  platform,
  data
}) => {
  const IsHasBrand =
    data?.campaign?.mustDoList?.length > 0 &&
    !!data?.campaign?.mustDoList.join('');

  // 默认 对应 Youtube
  let platformRequireMent = (
    <>
      <details>
        <summary>
          Dedicated video
          <Chevron />
        </summary>
        <div className={styles.markdownContainer}>
          {DedicatedVideoContents?.map((item: any, index: number) => (
            <Card key={index} item={item} />
          ))}
        </div>
      </details>
      <details>
        <summary>
          Integrated video
          <Chevron />
        </summary>
        <div className={styles.detailsInfo}>
          <div className={styles.markdownContainer}>
            {IntegratedVideoContents?.map((item: any, index: number) => (
              <Card key={index} item={item} />
            ))}
          </div>
        </div>
      </details>
    </>
  );

  if (platform === PlatformItems.TIKTOK) {
    platformRequireMent = (
      <details open={!IsHasBrand}>
        <summary>
          Platform requirements
          <Chevron />
        </summary>
        <div className={styles.markdownContainer} style={{ border: 'none' }}>
          {tTRequiredContents?.map((item: any, index: number) => (
            <Card key={index} item={item} />
          ))}
        </div>
      </details>
    );
  }

  if (platform === PlatformItems.INSTAGRAM) {
    platformRequireMent = (
      <details open={!IsHasBrand}>
        <summary>
          Platform requirements
          <Chevron />
        </summary>
        <div className={styles.markdownContainer} style={{ border: 'none' }}>
          {InstagramRequiredContents?.map((item: any, index: number) => (
            <Card key={index} item={item} />
          ))}
        </div>
      </details>
    );
  }

  if (platform === PlatformItems.TWITTER) {
    platformRequireMent = (
      <details open={!IsHasBrand}>
        <summary>
          Platform requirements
          <Chevron />
        </summary>
        <div className={styles.markdownContainer} style={{ border: 'none' }}>
          {TwitterRequiredContents?.map((item: any, index: number) => (
            <Card key={index} item={item} />
          ))}
        </div>
      </details>
    );
  }

  if (platform === PlatformItems.LINKEDIN) {
    platformRequireMent = (
      <details open={!IsHasBrand}>
        <summary>Platform requirements</summary>
        <div className={styles.markdownContainer} style={{ border: 'none' }}>
          {LinkedInRequiredContents?.map((item: any, index: number) => (
            <Card key={index} item={item} />
          ))}
        </div>
      </details>
    );
  }

  return (
    <div
      className={`${styles.collapsible} ${isMobile() ? styles.collapsibleMobile : ''}`}
      id="content-requirements"
    >
      {/* 平台要求 */}
      {platformRequireMent}
      {/* 品牌要求 */}
      {IsHasBrand && (
        <details open>
          <summary>
            Brand Requirements
            <Chevron />
          </summary>
          <div className={styles.detailsInfo}>
            <div className={styles.markdownContainer}>
              <ProductMustDo data={data} />
            </div>
          </div>
        </details>
      )}
    </div>
  );
};

export default ContentRequirements;
