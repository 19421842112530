import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { isMobile } from '@/biz/tool';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import ModalSelectContentPrice from './ModalSelectContentPrice';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import axiosInstance from '@/apis/axiosInstance';
import toast from 'react-hot-toast';

const ModalShowAnInterest: React.FC = () => {
  const { detailData, reQueryDetailData } = useDetailDataStore();
  const { order } = detailData;
  const orderId = order?._id;
  const [isOpen, setIsOpen] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = async () => {
    setSubmitBtnLoading(true);

    try {
      await axiosInstance.post(`/order/${orderId}/postpone`);
      toast.success('Submission success.');
      //成功之后关闭弹窗，并且隐藏按钮
      setIsOpen(false);
      // 提交后刷新页面数据
      reQueryDetailData({ orderId });
    } catch (err) {
      toast.error('Submission failed.');
      console.error(err);
    } finally {
      setSubmitBtnLoading(false);
    }
  };

  return (
    <>
      <>
        <Button
          variant="contained"
          className={`mui-btn-default ${isMobile() ? '!h-10' : ''}`}
          style={{
            display:
              order?.postponable && order.postponable === true
                ? 'block'
                : 'none'
          }}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Postpone timeline
        </Button>
        {isMobile() ? (
          <BottomDrawer
            title={<ModalTitle />}
            visible={isOpen}
            handleClose={() => setIsOpen(false)}
            okText="Submit"
            handleSubmit={() => handleSubmit()}
            isLoading={submitBtnLoading}
          >
            <ModalContent />
          </BottomDrawer>
        ) : (
          <WebModal
            title={<ModalTitle />}
            visible={isOpen}
            handleClose={() => setIsOpen(false)}
            width={560}
            okText="Submit"
            handleSubmit={() => handleSubmit()}
            isLoading={submitBtnLoading}
          >
            <ModalContent />
          </WebModal>
        )}
      </>
    </>
  );
};

export default ModalShowAnInterest;

const ModalContent = () => {
  return (
    <div className="flex flex-col mt-3">
      <div className="text-[#1c2024] text-base font-bold font-['Plus Jakarta Sans'] leading-normal">
        Are you sure to postpone the timeline?
      </div>
      <div className="text-[#60646c] text-sm font-normal font-['Geist'] leading-tight">
        Postponing the timeline will affect client experiences and might affect
        your credibiltiy on the platform. You can postpone the timeline to 2
        days later.
      </div>
    </div>
  );
};

const ModalTitle = () => {
  return (
    <div className="h-[22px] leading-[22px] flex text-[#b06700] text-xs font-bold px-2.5 py-0.5 bg-[#ffdec0] rounded-full flex-col justify-center items-center">
      Confirmation
    </div>
  );
};
