import React, { useState, useEffect } from 'react';
import { RightArrowIcon } from '@/components/common/icon';

const ModalAnalysisBreadcrumb: React.FC<{ index: number }> = ({ index }) => {
  return (
    <div className="flex items-center mt-4 gap-1">
      <div
        className={`pl-1 pr-1.5 py-1.5 rounded justify-center items-center gap-2.5 inline-flex ${
          index === 0 ? 'bg-[#f1f3f4]' : 'bg-transparent'
        }`}
      >
        <div className="text-[#60646c] text-xs font-medium font-['Inter'] pl-2">
          1. Connect your social
        </div>
      </div>
      <RightArrowIcon />
      <div
        className={`pl-1 pr-1.5 py-1.5 rounded justify-center items-center gap-2.5 inline-flex ${
          index === 1 ? 'bg-[#f1f3f4]' : 'bg-transparent'
        }`}
      >
        <div className="text-[#60646c] text-xs font-medium font-['Inter'] pl-2">
          2. Confirm deal price
        </div>
      </div>
      <RightArrowIcon />
      <div
        className={`pl-1 pr-1.5 py-1.5 rounded justify-center items-center gap-2.5 inline-flex ${
          index === 2 ? 'bg-[#f1f3f4]' : 'bg-transparent'
        }`}
      >
        <div className="text-[#60646c] text-xs font-medium font-['Inter'] pl-2">
          3. Submit interest
        </div>
      </div>
    </div>
  );
};

export default ModalAnalysisBreadcrumb;
