import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { PlusIcon } from '@/components/common/icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  PlatformIconComponent,
  getPlatformName,
  checkPhylloConfig
} from '@/biz/platform';
import PhylloSDK from '@/biz/phylloSDKInit';
import { usePlatformStore } from '@/hooks/usePlatformStore';
import { CertificationIcon } from '@/components/common/icon';
import { Tracker } from '@/utils';
import { ConnectButtonNoStyle } from '@/components/web/components/connectButtonNoStyle';
import { PlatformItems } from '@/interface/common';
import { getOrderId } from '@/biz/tool';

const ModalSelectPlatformContent: React.FC<{}> = () => {
  const { platformList, phylloSDKConfig, loading, fetchPlatformData } =
    usePlatformStore();
  const { influencers } = usePlatformStore();

  const search = window.location.search;
  const alwaysConnect = new URLSearchParams(search)?.get('alwaysConnect');

  useEffect(() => {
    fetchPlatformData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {platformList?.length > 0 ? (
        platformList.map((platform: any) => {
          //
          const platformFound = influencers?.find((influencer: any) => {
            return influencer.platform === platform.platform;
          });
          const isConnected = platformFound && !alwaysConnect ? true : false;

          return (
            <div
              key={platform.name}
              className="min-h-[60px] w-full px-3 py-4 rounded-lg border border-[#d9d9e0] flex justify-between items-center"
            >
              <div className="flex items-center gap-3">
                <div className="w-6 h-6">
                  <PlatformIconComponent
                    className="w-6 h-6"
                    platform={platform.platform}
                  />
                </div>
                {isConnected ? (
                  <div className="flex items-center text-[#60646c] text-xs gap-1">
                    <img
                      className="w-4 h-4 rounded-full"
                      src={platformFound?.profilePictureLink}
                      // alt="avatar"
                    />
                    @{platformFound?.name}
                  </div>
                ) : (
                  getPlatformName(platform.platform)
                )}
              </div>
              <ConnectBtn platform={platform} isConnected={isConnected} />
            </div>
          );
        })
      ) : (
        <div className="h-[60px] w-full px-3 py-4 rounded-lg border border-[#d9d9e0] flex justify-center items-center">
          No platforms found
        </div>
      )}
    </>
  );
};

export default ModalSelectPlatformContent;

export const ConnectBtn = ({
  platform,
  isConnected,
  urlShowModalAnalysis,
  uiStyle
}: {
  platform: any;
  isConnected: Boolean;
  urlShowModalAnalysis?: boolean;
  uiStyle?: string;
}) => {
  const [connectLoading, setConnectLoading] = useState(false);
  const { platformList, phylloSDKConfig, loading, fetchPlatformData } =
    usePlatformStore();
  const { influencers } = usePlatformStore();

  if (platform?.platform && !platform?.id && !isConnected) {
    platform = platformList.find((p: any) => p.platform === platform.platform);
  }

  const handleConnect = async (platform: any) => {
    const platformId = platform.id;
    const getRedirectUrl = ({
      platformType,
      workPlatformId,
      onboarding,
      urlShowModalAnalysis,
      orderId
    }: {
      platformType: string;
      workPlatformId: string;
      onboarding: boolean;
      urlShowModalAnalysis?: boolean;
      orderId?: string;
    }) => {
      return `${window.location.origin}/auth/${platformType}?work_platform_id=${workPlatformId}&onboarding=${onboarding}&urlShowModalAnalysis=${urlShowModalAnalysis}&orderId=${orderId}`;
    };

    const config = {
      clientDisplayName: 'Aha Creator',
      environment:
        process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging',
      userId: phylloSDKConfig.user_id,
      token: phylloSDKConfig.sdk_token,
      redirect: true,
      redirectURL: getRedirectUrl({
        platformType: platform.platform,
        workPlatformId: platformId,
        onboarding: Boolean(!influencers?.length),
        urlShowModalAnalysis: urlShowModalAnalysis,
        orderId: getOrderId()
      }),
      workPlatformId: platformId,
      singleAccount: true
    };

    if (!checkPhylloConfig(config)) {
      return;
    }
    let phylloSDK = new PhylloSDK();
    await phylloSDK.openPhylloSDK(config);
  };

  // if (uiStyle === 'blackConnect') {
  //   return (
  //     <div className="text-black">111</div>
  //   );
  // }
  //
  if (isConnected) {
    return <CertificationIcon className="w-6 h-6 flex-shrink-0" />;
  }

  if (platform?.platform === PlatformItems.YOUTUBE) {
    return (
      <ConnectButtonNoStyle
        platform={PlatformItems.YOUTUBE}
        buttonLoading={connectLoading}
        setButtonLoading={setConnectLoading}
        onboarding={Boolean(!influencers?.length)}
      >
        <ConnectBtnCustom uiStyle={uiStyle} loading={connectLoading} />
      </ConnectButtonNoStyle>
    );
  } else {
    return (
      <>
        <ConnectBtnCustom
          uiStyle={uiStyle}
          loading={connectLoading}
          onClick={() => {
            if (!isConnected) {
              // google 上报
              Tracker.click('connect platform');
              Tracker.click(`connect platform ${platform.platform}`);
              handleConnect(platform);
            }
          }}
        />
      </>
    );
  }
};

const ConnectBtnCustom = ({
  onClick,
  loading,
  uiStyle
}: {
  onClick?: () => void;
  loading?: boolean;
  uiStyle?: string;
}) => {
  if (uiStyle === 'blackConnect') {
    return (
      <Button
        className="mui-btn-black !w-[119px] !h-10 flex items-center justify-center"
        onClick={() => {
          onClick && onClick();
        }}
      >
        {loading ? (
          <div className="w-4 h-4 mr-1">
            <CircularProgress
              color="inherit"
              className="!w-4 !h-4"
              disableShrink
            />
          </div>
        ) : null}
        {loading ? 'Connecting...' : 'Connect'}
      </Button>
    );
  }

  return (
    <Button
      className="mui-btn-default !p-0 !w-6 !h-6 !min-w-6"
      onClick={() => {
        onClick && onClick();
      }}
    >
      {loading ? (
        <CircularProgress color="inherit" className="!w-6 !h-6" disableShrink />
      ) : (
        <PlusIcon className="w-6 h-6 " />
      )}
    </Button>
  );
};
