import {
  XPlatformIcon,
  InstagramPlatformIcon,
  TiktokPlatformIcon,
  YoutubePlatformIcon,
  LinkedInPlatformIcon
} from '@/components/common/icon';
import toast from 'react-hot-toast';
import { AdsPlatform } from '@/interface/order';
import {
  youtubeLinkValidator,
  tiktokLinkValidator,
  instagramReelsLinkValidator,
  twitterLinkValidator,
  linkValidator
} from '@/biz/validator';
import {
  submitModalDescDefault,
  submitModalDescYoutube,
  submitModalDescTiktok,
  submitModalDescInstagram
} from '@/components/web/config';
import { PlatformItems } from '@/interface/common';

export const getPlatformName = (platform: string) => {
  platform = platform?.toLowerCase();
  // 处理大小写
  if (PlatformItems.YOUTUBE.toLowerCase() === platform) return 'YouTube';
  if (PlatformItems.TIKTOK.toLowerCase() === platform) return 'TikTok';
  if (PlatformItems.INSTAGRAM.toLowerCase() === platform) return 'Instagram';
  if (PlatformItems.TWITTER.toLowerCase() === platform) return 'Twitter';
  if (PlatformItems.LINKEDIN.toLowerCase() === platform) return 'LinkedIn';
  return `${platform?.charAt(0).toUpperCase()}${platform?.slice(1)}`;
};

export const getPlatformVideoContentType = ({
  platform,
  contentType
}: {
  platform: string;
  contentType?: string;
}) => {
  if (contentType === 'Dedicated video' || contentType === 'Integrated video') {
    return contentType;
  }
  //
  if (PlatformItems.YOUTUBE.toLowerCase() === platform)
    return 'Dedicated video';
  if (PlatformItems.TIKTOK.toLowerCase() === platform) return 'Tiktok video';
  if (PlatformItems.INSTAGRAM.toLowerCase() === platform) return 'Reels';
  if (PlatformItems.TWITTER.toLowerCase() === platform) return 'Post';
  if (PlatformItems.LINKEDIN.toLowerCase() === platform) return 'Post';
  return platform;
};

export const PlatformIconComponent: React.FC<{
  platform: string;
  className?: string;
}> = ({ platform, className }) => {
  if (PlatformItems.YOUTUBE.toLowerCase() === platform)
    return <YoutubePlatformIcon className={className} />;
  if (PlatformItems.TIKTOK.toLowerCase() === platform)
    return <TiktokPlatformIcon className={className} />;
  if (PlatformItems.INSTAGRAM.toLowerCase() === platform)
    return <InstagramPlatformIcon className={className} />;
  if (PlatformItems.TWITTER.toLowerCase() === platform)
    return <XPlatformIcon className={className} />;
  if (PlatformItems.LINKEDIN.toLowerCase() === platform)
    return <LinkedInPlatformIcon className={className} />;
  return <div />;
};

export const checkPhylloConfig = (config: any) => {
  // 参数校验
  if (!config.clientDisplayName) {
    toast.error(
      'The clientDisplayName is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.environment) {
    toast.error(
      'The environment is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.userId) {
    toast.error(
      'The userId is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.token) {
    toast.error(
      'The token is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (config.redirect === true && !config.redirectURL) {
    toast.error(
      'The redirectURL is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  if (!config.workPlatformId) {
    toast.error(
      'The workPlatformId is empty, please refresh the page or contact customer service.'
    );
    return false;
  }
  return true;
};

export const platformDraftLinkValidator = (url: string, platform: string) => {
  if (platform === AdsPlatform.Youtube) {
    return youtubeLinkValidator(url);
  }
  return linkValidator(url);
};

export const platformFinalLinkValidator = (url: string, platform: string) => {
  if (platform === AdsPlatform.Youtube) {
    return youtubeLinkValidator(url);
  }
  if (platform === AdsPlatform.TikTok) {
    return tiktokLinkValidator(url);
  }
  if (platform === AdsPlatform.Instagram) {
    return instagramReelsLinkValidator(url);
  }
  if (platform === AdsPlatform.Twitter) {
    return twitterLinkValidator(url);
  }
  return linkValidator(url);
};

export const getSubmitModalDesc = (platform: string) => {
  if (platform === AdsPlatform.Youtube) {
    return submitModalDescYoutube;
  } else if (platform === AdsPlatform.TikTok) {
    return submitModalDescTiktok;
  } else if (platform === AdsPlatform.Instagram) {
    return submitModalDescInstagram;
  }
  return submitModalDescDefault;
};
